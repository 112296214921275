import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import  bg from '../images/lam-home-bg.jpeg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import waves from '../images/waves.png';
import table from '../images/table.jpg';
import recruit from '../images/recruit.jpg';
import badge from '../images/badge.png';
import submit from '../images/submit.jpg';
import map from '../images/lam-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCaretRight, faSearch, faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Navigation from './nav';


const Message = () => {

    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
           alert(data)
      
        let submitFinanceSchemeRequest = await fetch(' https://lamwebservices-bc4dfe5fe774.herokuapp.com/lamwebservices/v1/mailer-demon/helpdesk/inquiry',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };


    return(

<>
<Navigation currentVertical="" />  

<div className="row fullWidth mOff" style={{backgroundImage:`url(${bg})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 col-md-6 standard-padding-tb marginGlass2 top-text-clearance-2 contactSideBar'> 
   
        </div>

 
        <div className="col-12 col-md-6 whiteBackground "> 
        <form className='row fullWidth formArea' onSubmit={handleSubmit(onSubmit)} >

        <h4 className="BodyFont marginGlass3"> 
           Send Us A Message
        </h4>

        <h6 className="bodyFont greyText"> 
           We would like to hear from you
        </h6>

        <label className="bodyFont">
          Full name
        </label>       
        <input className='form-control  form-control-2' type="text" placeholder='Fullname' {...register("fullname", {required:true})}/>

         <label className="bodyFont">
              Email
         </label>
        <input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />

        <label className="bodyFont">
              Phone number
         </label>
        <input className='form-control form-control-2' type="number" placeholder='Phonenumber'{...register("mobileno", {required:true})} />

        <label className="bodyFont">
              Subject
         </label>
        <input className='form-control form-control-2' type="text" placeholder='subject'{...register("subject", {required:true})} />


         <label className="bodyFont">
               Comments
         </label>
        <input className='form-control form-control-2 commentsBoxHeight' type="textarea" placeholder='Your message' {...register("comments", {required:true})}/>
 


         <div className='col-12 col-md-12'>
              <button  className="btn btn-primary leadwayOrange noDecoration headerFont fullWidth" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>Submit</button>

         </div>
        </form>
        
        </div>
      
</div>
     

</>

    )
}

export default Message