import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import directoryBanner from '../images/directory.jpg';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Directory = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    const [teamBoardContent, setteamBoardContent] = useState([]);
    const [teamProfessionalContent, setteamProfessionalContent] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [bio, setBio] = useState("");
    const [linkedinProfile, setLinkedinProfile] = useState("");
    const [role, setRole] = useState("");
    const [picture, setPicture] = useState("");




    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
              pages {
                   edges {
                     node {
                       team {
                         teamBannerImage {
                           mediaItemUrl
                         }
                         teamBannerText
                         teamBoard {
                           biography
                           firstName
                           jobRole
                           lastName
                           linkedinProfile
                           picture {
                             mediaItemUrl
                           }
                         }
                         teamProfesionals {
                           firstName
                           lastName
                           jobRole
                           biography
                           linkedinProfile
                           picture {
                             mediaItemUrl
                           }
                         }
                       }
                     }
                   }
                 }
       }
         `,
       })
       .then(async result => { 
         let professionalsArray = []
     
             console.log(result.data.pages.edges[0].node.team.teamProfesionals)
             
        
              setteamBoardContent(result.data.pages.edges[0].node.team.teamBoard)
             // setteamProfessionalContent(result.data.pages.edges[0].node.team.teamProfesionals)

              for( let i=0; i <= result.data.pages.edges[0].node.team.teamProfesionals.length - 1; i++){
                console.log(params.id)
                console.log(params.id.length)
                console.log(result.data.pages.edges[0].node.team.teamProfesionals[i].firstName)
                
              let  firstname = result.data.pages.edges[0].node.team.teamProfesionals[i].firstName
              let fname =  firstname.replace(/\s/g,"")
                console.log(fname.split())
                 
                  if (fname === params.id){
                    console.log("rann")

                    setFirstName(result.data.pages.edges[0].node.team.teamProfesionals[i].firstName)
                    setLastName(result.data.pages.edges[0].node.team.teamProfesionals[i].lastName)
                    setRole(result.data.pages.edges[0].node.team.teamProfesionals[i].jobRole)
                    setLinkedinProfile(result.data.pages.edges[0].node.team.teamProfesionals[i].linkedinProfile)
                    setBio(result.data.pages.edges[0].node.team.teamProfesionals[i].biography)
                    setPicture (result.data.pages.edges[0].node.team.teamProfesionals[i].picture.mediaItemUrl)

                    return true
                   
                  }

                   }
                   if(!found){

                    for( let i=0; i <= result.data.pages.edges[0].node.team.teamBoard.length - 1; i++){
                      console.log(params.id)
                      console.log(params.id.length)
                      console.log(result.data.pages.edges[0].node.team.teamBoard[i].firstName)
                      
                    let  firstname = result.data.pages.edges[0].node.team.teamBoard[i].firstName
                    let fname =  firstname.replace(/\s/g,"")
                      console.log(fname.split())
                       
                        if (firstname === params.id){
                          console.log("rann")
      
                          setFirstName(result.data.pages.edges[0].node.team.teamBoard[i].firstName)
                          setLastName(result.data.pages.edges[0].node.team.teamBoard[i].lastName)
                          setRole(result.data.pages.edges[0].node.team.teamBoard[i].jobRole)
                          setLinkedinProfile(result.data.pages.edges[0].node.team.teamBoard[i].linkedinProfile)
                          setBio(result.data.pages.edges[0].node.team.teamBoard[i].biography)
                          setPicture (result.data.pages.edges[0].node.team.teamBoard[i].picture.mediaItemUrl)
      
                          return true
                         
                        }
      
                         }

                    
                  }
       })
     
        
        }, [])




  return (
    <>
      <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${directoryBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
              Directory
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
      


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign whiteText headerFont' variant="secondary" >
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>

   
    </>
  );
};

export default Directory