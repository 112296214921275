import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import  bg from '../images/lam-home-bg.jpeg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import waves from '../images/waves.png';
import table from '../images/table.jpg';
import recruit from '../images/recruit.jpg';
import badge from '../images/badge.png';
import submit from '../images/submit.jpg';
import map from '../images/lam-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCaretRight, faSearch, faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Navigation from './nav';


const Callback = () => {

    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
  
      
        let submitFinanceSchemeRequest = await fetch('https://lamwebservices-bc4dfe5fe774.herokuapp.com/lamwebservices/v1/mailer-demon/helpdesk/callback',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };


    return(

<>
<Navigation currentVertical="" />  

<div className="row fullWidth mOff" style={{backgroundImage:`url(${bg})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 col-md-6 standard-padding-tb marginGlass2 top-text-clearance-2 contactSideBar'> 
   
        </div>

 
        <div className="col-12 col-md-6 whiteBackground "> 
        <form className='row fullWidth formArea' onSubmit={handleSubmit(onSubmit)} >

        <h4 className="BodyFont marginGlass3"> 
           REQUEST A CALLBACK
        </h4>

        <h6 className="bodyFont greyText"> 
           Someone from our team would reach out to you
        </h6>

        <div className='row pOff'>
         <div className="col-12 col-md-6 plOff ">
           <label className="bodyFont">
            Firstname
          </label>
          <input className='form-control form-control-2' type="text" placeholder='Firstname' {...register("firstname", {required:true})}/>
         </div>

         <div className="col-12 col-md-6 prOff">
           <label className="bodyFont">
            Lastname
           </label>
         <input className='form-control form-control-2' type="text" placeholder='Lastname' {...register("lastname", {required:true})}/>
         </div>
         </div>

         <label className="bodyFont">
                   Business unit
              </label>
         <select className='form-control form-control-2' {...register("unit", {required:true})}>
            <option value="none">Select Business Unit</option>
            <option value="Investment">Investment</option>
           <option value="Client Services">Client Services </option>
           <option value="Executive Office"> Executive Office </option>
         </select>

         <label className="bodyFont">
               Phone number
          </label>
          <input className='form-control form-control-2' type="text" placeholder='Cell' {...register("mobileno", {required:true})}/>
 
         <label className="bodyFont">
            Email
         </label>
        <input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />

        <label className="bodyFont">
             Preffered Date
         </label>
        <input className='form-control form-control-2' type="date" placeholder='Select Preferred Date'{...register("dateCommitment", {required:true})} />


         <label className="bodyFont">
             Preferred Time
         </label>
         <select className='form-control form-control-2' {...register("timeCommitment", {required:true})}>
            <option value="Morning">Morning</option>
            <option value="Early Afternoon"> Early Afternoon </option>
           <option value="Afternoon"> Afternoon </option>
           <option value="Early Evening"> Early Evening </option>
         </select>

         <div className='col-12 col-md-12'>
              < button  className="btn btn-primary btnDesign headerFont fullWidth"> Submit </button>
         </div>

      </form>       
 </div>
      
</div>
     

</>

    )
}

export default Callback 