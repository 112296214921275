import React, { useState, useEffect, Component } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel'
import teamCover from '../images/hr.jpg';
import aboutCover from '../images/calendar.jpg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import e2 from '../images/e2.jpg';
import e1 from '../images/e1.jpg';
import as from '../images/as.jpg';
import docs from '../images/docs.jpg';
import calculator from '../images/calculator.jpg';
import advise from '../images/advise.jpg';
import investment from '../images/investment.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import ad from '../images/palms.jpg';
import Navigation from './nav';

const Resources = () => {

  window.scrollTo(0, 0)
    const options =  {
        loop:true,
        autoplay:true,
        autowidth:true,
        items:5,
        arrows:true,
        margin:20,
        responsiveClass:true,
        responsive:{
            1024:{
                items:5
            },
      
            320:{
              items:1 
            }
        }
      };

    
      const settings = {
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
      };


    return(

<>
<Navigation currentVertical="" />  
<div className="standard-banner-padding row fullWidth mOff" style={{background:`linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url(${teamCover}) center center`, backgroundSize:"cover",backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2"> 
     
    </h1>

</div>

<div className="row fullWidth mOff"  style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
<div className="col-10 mx-auto"> 
    <div className="row standard-padding-rl-tb">
    <div className="col-12  text-center"> 
        <h3 className="orangeText headerFont"> 
        Resources
         </h3>
         <hr className='barStyleGP mx-auto' />   
           <h6 className="bodyFont"> 
        Select a category to get started
         </h6>     
     </div>
    </div> 

    <div className="row standard-padding-tb-rl"> 

<div className="col-12 col-md-3 resourceBoxMargin">
 <div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${as})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseOne" role="button" aria-expanded="false" aria-controls="collapseExample">
    Account Services
  </a>

    </div>

    <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />
   <Link to="portal" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Portal     </Link> <br/>
   <a href="/contactforms" target="_blank" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Feedback      </a> <br/>
   <a href="/contact" data-target="_blank" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Directory        </a> <br/>
   <Link to="faq" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  FAQ's      </Link> 
 </div>
    </div>
  </div>
</div>

<div className="col-12 col-md-3 resourceBoxMargin mx-auto">
<div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${docs})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseExample">
    Documents & Terms
  </a>

    </div>

    <div id="collapseTwo" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />     
   <Link to="forms" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Forms   </Link> <br/>
   <Link to="policies" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} /> Investor Portal   </Link> <br/>
  </div>
    </div>
  </div>
</div>

<div className="col-12 col-md-3">

<div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${calculator})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseExample">
    Calculator & Tools
  </a>

    </div>

    <div id="collapseThree" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />
      <Link to="#" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Lifestyle Calculator    </Link> <br/>
  
   </div>
    </div>
  </div>
</div>

</div>

<div className="row standard-padding-tb"> 

<div className="col-12 col-md-3 resourceBoxMargin">

<div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${investment})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseExample">
    Investment 101
  </a>

    </div>

    <div id="collapseFour" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />
      <Link to="forms" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Forms    </Link> <br/>
</div> 
    </div>
  </div>
</div>

<div className="col-12 col-md-3 resourceBoxMargin mx-auto">

<div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${docs})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseExample">
    Downloads
  </a>

    </div>

    <div id="collapseFive" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />
      <Link to="press-kit" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Press kit    </Link> <br/>
      <Link to="#" className='noDecoration blackText resourceText'> <FontAwesomeIcon icon={faCaretRight} />  Financial reports    </Link> <br/>
        </div>
    </div>
  </div>
</div>

<div className="col-12 col-md-3 resourceBoxMargin">

<div className="card orangeShadow">
    <div className="card-header text-center resourceCardHeight" id="headingOne" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${advise})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <a class="btn btn-primary transBg noDecoration whiteText resourceBtnPosition headerFont" data-bs-toggle="collapse" href="#collapseSix" role="button" aria-expanded="false" aria-controls="collapseExample">
    Financial Advisory
  </a>

    </div>

    <div id="collapseSix" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div className="card-body" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
      <hr className='barStyle mx-auto' />
  </div>
    </div>
  </div>
</div>

</div>
    </div>

</div>

<div className='row text-center standard-padding-tb fullWidth mOff'>

<div className='col-12 mx-auto'>
<a href="#" className="btn btn-primary  btnDesignAlt headerFont">  Want to open an account ? </a>
</div>



</div>

<div className="standard-banner-padding-alt row fullWidth mOff" style={{backgroundImage:`url(${ad})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h2 className="bodyFont lightFont whiteText"> 
     All your assets... 
    </h2>
    <h1 className='headerFont whiteText'> Right in your palms. </h1>

    <h5 className="bodyFont lightFont whiteText"> Download our mobile app today </h5>
    <div className="col-12 col-md-2">
    <a href="#" className="btn btn-primary leadwayOrange btnDesignAlt headerFont" > Download Now </a>

    </div>
   
</div>

<Outlet /> 
</>

    )


}

export default Resources