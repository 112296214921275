import React, { useState, useEffect, Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import {useParams, Link} from 'react-router-dom';
import  bg from '../images/logo.png'
import table from '../images/table.jpg';
import $, { post } from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import Navigation from './nav';
import waves from '../images/waves.png';



import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });



const Verticalblog = (props) => {



    const [privateWealth, setPrivateWealth] = useState(false)
    const [indivdualInvestor, setIndividualInvestor] = useState(false)
    const [institutionalInvestor, setInstitutionalInvestor]= useState(false)
    const [selectedPostTitle, setSelectedPostTitle] = useState("")
    const [selectedPostDate, setSelectedPostDate] = useState("")
    const [selectedPostName, setSelectedPostName] = useState("")
    const [selectedPostContent, setSelectedPostContent] = useState("")
    const [selectedPostVideo, setSelectedPostVideo] = useState("")
    const [selectedPostImage, setSelectedPostImage] = useState("")
    const [recommendedContent, setRecommendedContent] = useState([])

  

    
    const params = useParams()

    let vertical = params.verticalName
    let verticalSanitized = vertical.replace("-", " ")

    let verticalCategory = parseInt(params.categoryId)
    let categoryId = verticalCategory

    useEffect( () => {

        console.log(params.postType)

        let category = parseInt(params.categoryId)

// codeblock for blog content

    if(params.postType === "Research"){

   
      client
  .query({
    query: gql`
    query($id:ID!) {
      researchBy(id:$id) {
        id
        verticalBlog {
            blogContent
            blogVideo
            blogBanner{
                mediaItemUrl
            }
          }
          title
          date
          contentType {
            node {
              name
            }
          }
        }
      }
 
    `,
    variables: {id:params.postId}
    })
  .then(async result => {

     console.log (result.data.researchBy.verticalBlog.blogVideo)
     setSelectedPostImage(result.data.researchBy.verticalBlog.blogBanner.mediaItemUrl)
     setSelectedPostTitle(result.data.researchBy.title)
     setSelectedPostContent(result.data.researchBy.verticalBlog.blogContent)
     setSelectedPostVideo(result.data.researchBy.verticalBlog.blogVideo)
      
  })


    }



 if(params.postType === "Market Commentary"){

        client
    .query({
      query: gql`
      query($id:ID!) {
        marketCommentaryBy(id:$id) {
          id
          verticalBlog {
              blogContent
              blogVideo
              blogBanner{
                  mediaItemUrl
              }
            }
            title
            date
            contentType {
              node {
                name
              }
            }
          }
        }
   
      `,
      variables: {id:params.postId}
      })
    .then(async result => {
  
       console.log (result.data.marketCommentaryBy.verticalBlog.blogVideo)
       setSelectedPostImage(result.data.marketCommentaryBy.verticalBlog.blogBanner.mediaItemUrl)
       setSelectedPostTitle(result.data.marketCommentaryBy.title)
       setSelectedPostContent(result.data.marketCommentaryBy.verticalBlog.blogContent)
       setSelectedPostVideo(result.data.marketCommentaryBy.verticalBlog.blogVideo)
        
    })
  
  
      }



 if(params.postType === "Emerging Trends"){

    client
.query({
  query: gql`
  query($id:ID!) {
    emergingTrendsBy(id:$id) {
      id
      verticalBlog {
          blogContent
          blogVideo
          blogBanner{
              mediaItemUrl
          }
        }
        title
        date
        contentType {
          node {
            name
          }
        }
      }
    }

  `,
  variables: {id:params.postId}
  })
.then(async result => {

   console.log (result.data.emergingTrendsBy.verticalBlog.blogVideo)
   setSelectedPostImage(result.data.emergingTrendsBy.verticalBlog.blogBanner.mediaItemUrl)
   setSelectedPostTitle(result.data.emergingTrendsBy.title)
   setSelectedPostContent(result.data.emergingTrendsBy.verticalBlog.blogContent)
   setSelectedPostVideo(result.data.emergingTrendsBy.verticalBlog.blogVideo)
    
})


  }



 if(params.postType === "Portfolio"){

    client
.query({
  query: gql`
  query($id:ID!) {
    portfolioBy(id:$id) {
      id
      verticalBlog {
          blogContent
          blogVideo
          blogBanner{
              mediaItemUrl
          }
        }
        title
        date
        contentType {
          node {
            name
          }
        }
      }
    }

  `,
  variables: {id:params.postId}
  })
.then(async result => {

   console.log (result.data.portfolioBy.verticalBlog.blogVideo)
   setSelectedPostImage(result.data.portfolioBy.verticalBlog.blogBanner.mediaItemUrl)
   setSelectedPostTitle(result.data.portfolioBy.title)
   setSelectedPostContent(result.data.portfolioBy.verticalBlog.blogContent)
   setSelectedPostVideo(result.data.portfolioBy.verticalBlog.blogVideo)
    
})


  }






 if(params.postType === "Featured"){

    client
.query({
  query: gql`
  query($id:ID!) {
    featuredBy(id:$id) {
      id
      verticalBlog {
          blogContent
          blogVideo
          blogBanner{
              mediaItemUrl
          }
        }
        title
        date
        contentType {
          node {
            name
          }
        }
      }
    }

  `,
  variables: {id:params.postId}
  })
.then(async result => {

   console.log (result.data.featuredBy.verticalBlog.blogVideo)
   setSelectedPostImage(result.data.featuredBy.verticalBlog.blogBanner.mediaItemUrl)
   setSelectedPostTitle(result.data.featuredBy.title)
   setSelectedPostContent(result.data.featuredBy.verticalBlog.blogContent)
   setSelectedPostVideo(result.data.featuredBy.verticalBlog.blogVideo)
    
  })


  }

  // end of code block for page content extraction



//code block for content recommendation


  
  let recommendedContentArray = []
  //research Data extraction

  if (params.postType != "Research" ){
     client
     .query({
       query: gql`
       query($id: Int!) {
         allResearch(where:{categoryId:$id}) {
           edges {
             node {
               id
               date
               title
               verticalBlog {
                 description
               }
               contentType {
                 node {
                   description
                   name
                 }
               }
               featuredImage {
                 node {
                   mediaItemUrl
                 }
               }
             
             }
           }
         }
     }
     `,
     variables: {id:category}
     })
     .then(async result => {
       console.log(result.data.allResearch.edges[0].node)     
       
        let researchDetailPack 
        researchDetailPack =[{date:result.data.allResearch.edges[0].node.date, Image:result.data.allResearch.edges[0].node.featuredImage.node.mediaItemUrl, title: result.data.allResearch.edges[0].node.title, postId: result.data.allResearch.edges[0].node.id, postName:result.data.allResearch.edges[0].node.__typename, description:result.data.allResearch.edges[0].node.verticalBlog.description } ]
        let updatedArray = [...recommendedContent, researchDetailPack]        
        setRecommendedContent(updatedArray)
                    
     })

    }



  //marktetCommentary extraction

  if(params.postType != "Market Commentary"){
    client
    .query({
      query: gql`
      query($id: Int!) {
        allMarketCommentary(where:{categoryId:$id}) {
          edges {
            node {
              id
              date
              title
              verticalBlog {
                description
              }
              featuredImage {
                node {
                  mediaItemUrl
                }
              }
              contentType {
                node {
                  description
                  name
                }
              }
            }
          }
        }
    }
    `,
    variables: {id:category}
    })
    .then(async result => {

       console.log(result.data.allMarketCommentary.edges)
       let commentaryDetailPack 
      commentaryDetailPack =[{date:result.data.allMarketCommentary.edges[0].node.date, Image:result.data.allMarketCommentary.edges[0].node.featuredImage.node.mediaItemUrl, title: result.data.allMarketCommentary.edges[0].node.title, postId: result.data.allMarketCommentary.edges[0].node.id, postName:result.data.allMarketCommentary.edges[0].node.__typename, description:result.data.allMarketCommentary.edges[0].node.verticalBlog.description} ]
      let updatedArray = [...recommendedContent, commentaryDetailPack]        
      setRecommendedContent(updatedArray)
                 
    })

  }


//emergingTrends extraction

  if(params.postType != "Emerging Trends"){ 

            client
            .query({
              query: gql`
              query($id: Int!) {
                allEmergingTrends(where:{categoryId:$id}) {
                  edges {
                    node {
                      id
                      date
                      title
                      verticalBlog {
                        description
                      }
                      featuredImage {
                        node {
                          mediaItemUrl
                        }
                      }
                      contentType {
                        node {
                          description
                          name
                        }
                      }
                    }
                  }
                }
            }
            `,
            variables: {id:category}
            })
            .then(async result => {
      
               console.log(result.data.allEmergingTrends.edges)

               let trendsDetailPack 
                            
              trendsDetailPack =[{date:result.data.allEmergingTrends.edges[0].node.date, Image:result.data.allEmergingTrends.edges[0].node.featuredImage.node.mediaItemUrl, title: result.data.allEmergingTrends.edges[0].node.title, postId: result.data.allEmergingTrends.edges[0].node.id, postName:result.data.allEmergingTrends.edges[0].node.__typename, description:result.data.allEmergingTrends.edges[0].node.verticalBlog.description} ]
              let updatedArray = [...recommendedContent, trendsDetailPack]        
              setRecommendedContent(updatedArray)
             
        
            })

          }

            //portfolio extraction
       if(params.postType != "Portfolio"){
            client
            .query({
              query: gql`
              query($id: Int!) {
                allPortfolio(where:{categoryId:$id}) {
                  edges {
                    node {
                      id
                      date
                      title
                      verticalBlog {
                        description
                      }
                      
                      featuredImage {
                        node {
                          mediaItemUrl
                        }
                      }
                      contentType {
                        node {
                          description
                          name
                        }
                      }
                    }
                  }
                }
            }
            `,
            variables: {id:category}
            })
            .then(async result => {
      
               console.log(result.data.allPortfolio.edges[0].node.contentType)
           
               let portfolioDetailPack 
            
                portfolioDetailPack =[{date:result.data.allPortfolio.edges[0].node.date, Image:result.data.allPortfolio.edges[0].node.featuredImage.node.mediaItemUrl, title: result.data.allPortfolio.edges[0].node.title, postId: result.data.allPortfolio.edges[0].node.id, postName:result.data.allPortfolio.edges[0].node.__typename, description:result.data.allPortfolio.edges[0].node.verticalBlog.description } ]
                recommendedContentArray.push(portfolioDetailPack)     
                let updatedArray = [...recommendedContent, portfolioDetailPack]        
                setRecommendedContent(updatedArray)
                 
            })
          
       }



// end of code block for content recommendation section

  




  function identifyVertical() {

      if (params.verticalName === "institutional-investor"){
           console.log("III")
          setInstitutionalInvestor(true)
      }


      if (params.verticalName === "individual-investor"){
          setIndividualInvestor(true)
          console.log("II")
      }


      if (params.verticalName === "private-wealth"){
          setPrivateWealth(true)
          console.log("pw")
      }
    }

    identifyVertical()
    window.scrollTo(0, 0)
 }, [])
    
    
 let videoPostContent

 console.log(selectedPostVideo)

 if(!selectedPostVideo ){
  videoPostContent = " "
 }else{

  videoPostContent = 
  <iframe width="100%" height="480" src={selectedPostVideo} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

 }




 let recommendedContentDetails
 console.log(recommendedContent)
 if(recommendedContent.length == 3){

  

   recommendedContentDetails = recommendedContent.map((recommendedContentDetails, i) => 
  
   <div className="col-12 col-md-4"> 
   <h6 className="insightDescription">{recommendedContentDetails[0].postName}</h6>
               <hr className='barStyleInsights' />

   <img src={recommendedContentDetails[0].Image} className="img-fluid" width="%" /> 
   <p className="insightDescription">{recommendedContentDetails[0].date}</p>
   <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${recommendedContentDetails[0].postName}/${recommendedContentDetails[0].postId}`} >
  <h5 className="insightHeading">{recommendedContentDetails[0].title}</h5>
  </Link>
  <p className="insightDescription"> {recommendedContentDetails[0].description}</p>
  </div>
 
      )

      
   }

    return(
        <>

<Navigation currentVertical={params.verticalName} currentVerticalId={params.verticalId} />

<div className="standard-banner-padding-top-vertical row fullWidth mOff" style={{backgroundImage:`url(${selectedPostImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
<div className= {`standard-banner-padding-vertical ${privateWealth ? 'greyBackground' : indivdualInvestor ? 'orangeBackground' : institutionalInvestor ? 'blueBackground': false }`}> 
      <div className="col-12 text-center"> 
          <h2 className="whiteText lightFont">
               {selectedPostTitle}
          </h2>
          <hr className='barStyle mx-auto' />
         <h6 className="whiteText">
            {params.postType}
         </h6>

      </div>
         </div>
</div>


<div className="" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> 


     <div className="col-12 col-md-10 mx-auto standard-padding-rl-2 standard-padding-tb-2 glassBackground text-center">
    <p className="bodyFont"> 
       {selectedPostContent}
        </p>
        </div>
     <div className=""> 
  {videoPostContent}
<div className="row text-center teamBoxMargin fullWidth mOf marginGlass2">
    <div className="col-12">
    <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights`} className="btn btn-primary leadwayOrange btnDesign2 headerFont" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>Back to {verticalSanitized} insights</Link>

    </div>
</div>



<div className="col-12 standard-padding-tb"> 

<h4 className="headerFont text-center greyText2"> 
      You might also be interested in
   </h4>
   <hr className='barStyleGP mx-auto' />

</div>


     <div className="col-12 col-md-10 mx-auto vertical-padding-bottom"> 
      <div className="row fullWidth mOff" id="flex1">
     
      {recommendedContentDetails}

  

      </div>


    

     
     </div>



    
     </div>
</div>


</>
    )

    
}

export default Verticalblog;