import React, { useState, useEffect, Component } from 'react';
import {useParams, Link} from 'react-router-dom';
import  bg from '../images/lam-white.png'
import  bgLam from '../images/logo.png'
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretRight, faSearch, faUserGear } from '@fortawesome/free-solid-svg-icons'
import waves from '../images/waves.png';
import Vertical from './veritcal';



const Navigation = (props) => {

    const [navbar, setNavbar] = useState(false);
    const [currentVertical, setCurrentVertical] = useState(props.currentVertical)
    const [verticalId, setverticalId] = useState(props.currentVerticalId)
    const [currentCategory, setCurrentCategory] = useState(props.currentCategory)

    const params = useParams()

   
   console.log(currentVertical)
    const changeBackground = () => {
        if(window.scrollY >= 1){
            setNavbar(true);
        }else{
            setNavbar(false);
        }
    }
    
    window.addEventListener('scroll', changeBackground);
    
   
    

    const menuCloseControls = () => { 
         
        let menuHouse = document.getElementById('menuHouse')

        $(menuHouse).fadeOut('fast')

         
    }


    const menuOpenControls = () => { 
         
    let menuHouse = document.getElementById('menuHouse')
        $(menuHouse).fadeIn('fast')
         
    }



    if(currentVertical == ""){
 
  
       return(
           <>
   
   
   <div className="menu" id="menuHouse">
         
   <div className="card cardDesignMenu">
      
      <div className="card-body whiteBg pOff">
      <div className='row'>
      <div className="col-md-3 menuColumn">
   
       <div className="row"> 
       <div className="col-10 text-center standard-padding-rl-tb-menu-inner"> 
       <Link to="/">  <img src={bgLam} width="70%" className="logoMargin menuLogoPosition" onClick={menuCloseControls}/>    </Link> 
        </div>
        <div className="col-2 text-left"> 
        <FontAwesomeIcon icon={faXmark} className="menuCloseFont" id="menuClose" onClick={menuCloseControls}> <img src={bg} width="50%" className="logoMargin"/> </FontAwesomeIcon>
        
        </div>
        
    
       </div>
   
       <hr className='barStyleMenu mx-auto' />
   
       <div className="row"> 
   
       <div className="col-12"> 
       <div class="d-flex align-items-start">
            <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
   
        <Link to="/resources" class="nav-link headerFont btnDesignAlt3 menuFontSize noDecoration" onClick={menuCloseControls}> <FontAwesomeIcon icon={faCaretRight} /> Resources </Link>
       <a class="nav-link headerFont active btnDesignAlt3 menuFontSize" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false"> <FontAwesomeIcon icon={faCaretRight} /> Our Firm </a>
       
      
           </div>
       </div>
       </div>
    
        </div>
        </div>
   
      <div className="col-md-9 menuSelectionColumn pOff">
       <div className="col-12 standard-padding-tb"> 
       <div class="tab-content" id="v-pills-tabContent">
       <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
           <div className="row"> 
           <div className="col-12 menuHeadingClearance3">
           <h6 className="headerFont heavyText orangeText">Investment Solutions </h6>
           <hr className='barStyleMenuAlt' />
           </div>
           <div className="col-12 "> 
           <div className="menuLinkWrapper">
           <Link to="/about" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Who we are  <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to="/team" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Our Team <FontAwesomeIcon icon={faCaretRight}  className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to="/career" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Careers <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to="/contact" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Contact Us <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
                
           </div>
           </div>
          
          
           </div>
   
           <div class="tab-pane fade" id="v-pills-about" role="tabpanel" aria-labelledby="v-pills-about-tab">
           <div className="row"> 
           <div className="col-12 menuHeadingClearance3">
           <h6 className="headerFont heavyText orangeText"> Related Categories </h6>
           <hr className='barStyleMenuAlt' />
           </div>
           <div className="col-12 "> 
           <div className="menuLinkWrapper">
           <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#research`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Research  <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#commentary`}  class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Market Commentary <FontAwesomeIcon icon={faCaretRight}  className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#portfolio`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Portfolio <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
           <div className="menuLinkWrapper">
           <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#trends`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Emerging Trends <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
           </div>
                
           </div>
           </div>
          
          
           </div>
         </div>
       </div>
   
       <div className="col-12 standard-padding-rl-tb blackBg d-block d-sm-none">
       <form className="row g-3 fullWidth text-center">
     <div className="col-10 pOff">
       <label for="inputPassword2" className="visually-hidden">Password</label>
       <input type="password" className="form-control footerForm" id="inputPassword2" placeholder="Looking for something or someone ?"/>
     </div>
     <div className="col-auto pOff">
       <button type="submit" className="btn btn-primary mb-3 footerBtn leadwayOrange standard-padding-tb-rl" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> <FontAwesomeIcon icon={faSearch} />  </button>
     </div>
   </form>
       </div>
   
   
       
        </div> 
      </div>
   
          </div>
    </div>
   
    </div>
        
   <div class={`row fixed-top standard-padding-tb-nav ${navbar ? 'color_bg' : 'trans_bg'}`}>
   
   
     <div className="menu-btn col-2 col-md-1 pOff" id="menu-btn" onClick={menuOpenControls}>
       <span className="whitebg"></span>
       <span className="whitebg"></span>
       <span className="whitebg"></span>
     
    </div>
    <div className="col-4 col-md-4 pOff">
     <Link to="/">  <img src={bg} width="35%" className="logoMargin logoSize"/> </Link>
    </div>
   
   <div class="dropdown offset-3 col-2 offset-md-6 col-md-1">
     <button class="btn btn-secondary blackBg dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
     <FontAwesomeIcon  className="" icon={faUserGear} /> 
     </button>
     <ul class="dropdown-menu blackBg">
       <li><a class="dropdown-item orangeText" href="/vertical/institutional-investor/home/cG9zdDoxMDE=">Institutional Investor</a></li>
       <li><a class="dropdown-item orangeText" href="/vertical/private-wealth/home/cG9zdDoxMDA=">Private Wealth</a></li>
       <li><a class="dropdown-item orangeText" href="/vertical/individual-investor/home/cG9zdDoxMDI=">Individual Investor</a></li>
     </ul>
   </div>
    
   
   </div>
   
   
   
   </>
       )
   
       }




    if(currentVertical != null){

     

 
    return(
        <>


<div className="menu" id="menuHouse">
      
<div className="card cardDesignMenu">
   
   <div className="card-body whiteBg pOff">
   <div className='row'>
   <div className="col-md-3 menuColumn">

    <div className="row"> 

    <div className="col-10 text-center standard-padding-rl-tb-menu-inner"> 
    <Link to="/">  <img src={bgLam} width="70%" className="logoMargin menuLogoPosition" onClick={menuCloseControls}/>    </Link> 
     </div>
     <div className="col-2 text-left"> 
     <FontAwesomeIcon icon={faXmark} className=" menuCloseFont" id="menuClose" onClick={menuCloseControls}> <img src={bg} width="100%" className="logoMargin"/> </FontAwesomeIcon>
     
     </div>
     
   
    </div>

    <hr className='barStyleMenu mx-auto' />

    <div className="row"> 

    <div className="col-12"> 
    <div class="d-flex align-items-start">
         <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">

    <a class="nav-link headerFont btnDesignAlt3 menuFontSize" id="v-pills-about-tab" data-bs-toggle="pill" data-bs-target="#v-pills-about" type="button" role="tab" aria-controls="v-pills-about" aria-selected="false"> <FontAwesomeIcon icon={faCaretRight} /> Insights </a>
    <Link to="/resources" class="nav-link headerFont btnDesignAlt3 menuFontSize noDecoration" onClick={menuCloseControls}> <FontAwesomeIcon icon={faCaretRight} /> Resources </Link>
    <a class="nav-link headerFont active btnDesignAlt3 menuFontSize" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="false"> <FontAwesomeIcon icon={faCaretRight} /> Our Firm </a>
    
   
        </div>
    </div>
    </div>
 
     </div>
     </div>

   <div className="col-md-9 menuSelectionColumn pOff">
    <div className="col-12 standard-padding-tb"> 
    <div class="tab-content" id="v-pills-tabContent">
    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
        <div className="row"> 
        <div className="col-12 menuHeadingClearance3">
        <h6 className="headerFont heavyText orangeText">Investment Solutions </h6>
        <hr className='barStyleMenuAlt' />
        </div>
        <div className="col-12 "> 
        <div className="menuLinkWrapper">
        <Link to="/about" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Who we are  <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to="/team" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Our Team <FontAwesomeIcon icon={faCaretRight}  className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to="/career" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Careers <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to="/contact" class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Contact Us <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
             
        </div>
        </div>
       
       
        </div>

        <div class="tab-pane fade" id="v-pills-about" role="tabpanel" aria-labelledby="v-pills-about-tab">
        <div className="row"> 
        <div className="col-12 menuHeadingClearance3">
        <h6 className="headerFont heavyText orangeText"> Related Categories </h6>
        <hr className='barStyleMenuAlt' />
        </div>
        <div className="col-12 "> 
        <div className="menuLinkWrapper">
        <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#research`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Research  <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#commentary`}  class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} >  Market Commentary <FontAwesomeIcon icon={faCaretRight}  className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#portfolio`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Portfolio <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
        <div className="menuLinkWrapper">
        <Link to={`/vertical/${currentVertical}/${verticalId}/${currentCategory}/insights#trends`} class="nav-link bodyFont btnDesignAlt2 menuHeadingClearance standard-padding-tb-submenu nav-menu-width" onClick={menuCloseControls} > Emerging Trends <FontAwesomeIcon icon={faCaretRight} className="rightFloat" /> </Link>
        </div>
             
        </div>
        </div>
       
       
        </div>
      </div>
    </div>

     </div> 
   </div>

       </div>
 </div>

 </div>
     
<div class={`row fixed-top standard-padding-tb-nav ${navbar ? 'color_bg' : 'trans_bg'}`}>


	<div className="menu-btn col-2 col-md-1 pOff" id="menu-btn" onClick={menuOpenControls}>
		<span className="whitebg"></span>
		<span className="whitebg"></span>
		<span className="whitebg"></span>
  
 </div>
 <div className="col-4 col-md-4 pOff">
  <Link to="/">  <img src={bg} width="35%" className="logoMargin logoSize"/> </Link>
 </div>

<div class="dropdown offset-3 col-2 offset-md-6 col-md-1">
  <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <FontAwesomeIcon icon={faUserGear} /> 
  </button>
  <ul class="dropdown-menu blackBg">
    <li><a class="dropdown-item orangeText" href="/vertical/institutional-investor/home/cG9zdDoxMDE=">Institutional Investor</a></li>
    <li><a class="dropdown-item orangeText" href="/vertical/private-wealth/home/cG9zdDoxMDA=">Private Wealth</a></li>
    <li><a class="dropdown-item orangeText" href="/vertical/individual-investor/home/cG9zdDoxMDI=">Individual Investor</a></li>
  </ul>
</div>
 

</div>



</>
    )

    }



    
}

export default Navigation;