import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import  bg from '../images/lam-home-bg.jpeg';
import waves from '../images/waves.png';
import Navigation from './nav';

import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });



const Homepage = () => {

  window.scrollTo(0, 0)
 

   
  const [cooperateMessage , setCorporateMessage] = useState("");
  const [indivdualInvestor , setIndividualInvestor] = useState("");
  const [privateWealth , setPrivateWealth] = useState("");
  const [welcomeMessage, setWelcomeMessage] = useState("");
  const [subCopy, setSubCopy] = useState("");
  const [intro, setIntro] = useState("");
  const [generalPage, setGeneralPage] = useState(true)
  






  useEffect( () => {

    

     
    client
  .query({
    query: gql`
    query{
    pages {
      edges {
        node {
          homepage {
            corporateAndFinancialInstitution
            fieldGroupName
            individualInvestor
            introSubMessage
            introductionMessage1
            privateWealth
            welcomeMessage
          }
        }
      }
    }
  }
    `,
  })
  .then(async result => { 

        console.log(result.data.pages)
        setCorporateMessage(result.data.pages.edges[9].node.homepage.corporateAndFinancialInstitution)
        setIndividualInvestor(result.data.pages.edges[9].node.homepage.individualInvestor)
        setPrivateWealth(result.data.pages.edges[9].node.homepage.privateWealth)
        setWelcomeMessage(result.data.pages.edges[9].node.homepage.welcomeMessage)
        setSubCopy(result.data.pages.edges[9].node.homepage.introSubMessage)
        setIntro(result.data.pages.edges[9].node.homepage.introductionMessage1)        

  })



  async function  getPersonalizationData(){ 

    console.log("ran")
    let personalizationData = await fetch('https://charisbrain.herokuapp.com/charis/lamwebsiteservices/personalisation/retrieveverticalData',{ 
      method: "POST",
      headers:{
        'Content-Type':'application/JSON',
        'Accept': 'application/JSON'
      },
      mode:'cors',
      credentials:'include'  
    })
  
    .then(response => response.json())
    .then(response => { 
        console.log(response)

        if (!response){
          return false
        }

        if(response){
          if(response === "1"){
            window.location.replace("/vertical/private-wealth/home/cG9zdDoxMDA=")
          }
          if(response === "2"){
            window.location.replace("/vertical/institutional-investor/home/cG9zdDoxMDE=")
          }
          if(response === "3"){
            window.location.replace("/vertical/individual-investor/home/cG9zdDoxMDI=")
          }
        }
    
    })
   }

  getPersonalizationData()

   
   }, [])



    return(
        
        <>

        <Navigation generalPage={generalPage} currentVertical="" />  

 <div style={{backgroundImage:`url(${bg})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
  
     <div className="row pOff mOff"> 
         
         <div className="text-left" id="landingContentArea">
         
         <div className="col-12 col-md-6 top-text-clearance menuHeadingClearance2">
         
         <h1 className="whiteText heavyFont headerFont "> {intro} </h1>
         <h4 className="bodyFont whiteText"> {subCopy} </h4>
         <p className='bodyFont whiteText'> 
           {welcomeMessage}
         </p>
         </div>

         <div className='col-12 col-md-10 mx-auto'>
        <div className="row standard-padding-tb" id="flex1">

      
         <div className="col-10 col-md-4 centerMobile mBMobile">

         <div className="card text-center cardDesign">
  <div className="card-body cardBodyDesign">
    <h5 className="card-title headerFont">Institutional <br/> Investor</h5>
    <hr className='barStyle mx-auto' />
    <p className="card-text greyText midWeight">
      {cooperateMessage}
</p>
<Link to="/vertical/institutional-investor/home/cG9zdDoxMDE=" className="btn btn-primary leadwayOrange btnDesign headerFont">Learn More</Link>
     </div>
</div>
            
         </div>

         <div className="col-10 col-md-4 mBMobile">
            
            <div className="card text-center cardDesign">
     <div className="card-body cardBodyDesign">
       <h5 className="card-title headerFont">Private <br/> Wealth</h5>
       <hr className='barStyle mx-auto' />
       <p className="card-text greyText midWeight">
        {privateWealth}
       </p>
      
       <Link to="/vertical/private-wealth/home/cG9zdDoxMDA=" className="btn btn-primary leadwayOrange btnDesign headerFont">Learn More</Link>
     </div>
   </div>
               
            </div>


            <div className="col-10 col-md-4 centerMobile mBMobile">
            
            <div className="card text-center cardDesign">
   
     <div className="card-body cardBodyDesign">
       <h5 className="card-title headerFont">Individual <br/> Investor</h5>
       <hr className='barStyle mx-auto' />
       <p className="card-text greyText midWeight">
          {indivdualInvestor}
          </p>
       <Link to="/vertical/individual-investor/home/cG9zdDoxMDI=" className="btn btn-primary leadwayOrange btnDesign headerFont">Learn More</Link>
      </div>
   </div>
               
            </div>

            </div>
         </div>
         </div> 


     </div>
      
    </div>

        </>
   

    )


}

export default Homepage;