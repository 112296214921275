import './App.css';
import "../node_modules/slick-carousel/slick/slick.css"; 
import "../node_modules/slick-carousel/slick/slick-theme.css";
import Homepage from './components/homepage';
import Navigation from './components/nav';
import About from './components/about';
import Career from './components/career';
import Team from './components/team';
import Portal from './components/portal';
import Faq from './components/faq';
import Feedback from './components/feedback';
import Directory from './components/directory';
import Apps from './components/apps';
import Form from './components/form';
import Privacy from './components/dataprivacy';
import Press from './components/press';
import Finance from './components/finance';
import Profile from './components/profile';
import Resources from './components/resources';
import Footer from './components/footer.js';
import Page from './components/page.js';
import PageTwo from './components/page2.js';
import VBlog from './components/verticalBlog.js';
import Contact from './components/contact.js';
import ContactForms from './components/contactForms.js';
import Vertical from './components/veritcal.js';
import Insights from './components/insights';
import Message from './components/message';
import Complaint from './components/complaint';
import Callback from './components/callback';
import {Routes, Route} from 'react-router-dom';


function App() {
  return (
    <div>
      <header> 
        <Navigation/>
      </header>
    <Routes> 

       <Route exact path="/" element={<Homepage/>}/>
       <Route exact path="/about" element={<About/>}/>
       <Route exact path="/career" element={<Career/>}/>
       <Route exact path="/team" element={<Team/>}>
             <Route path=":id" element={<Profile/>}/>
        </Route>
        <Route exact path="/resources" element={<Resources/>}>
           <Route path="portal" element={<Portal/>}/>
           <Route path="faq" element={<Faq/>}/>
           <Route path="feedback" element={<Feedback/>}/>
           <Route path="directory" element={<Directory/>}/>
           <Route path="financial-reports" element={<Finance/>}/>
           <Route path="press-kit" element={<Press/>}/>
           <Route path="apps" element={<Apps/>}/>
           <Route path="forms" element={<Form/>}/>
          <Route path="policies" element={<Privacy/>}/>
          
        </Route>
        <Route exact path="/vertical/:verticalName/:verticalId/:categoryId/insights" element={<Insights/>}/>
        <Route exact path="/footer" element={<Footer/>}/>
        <Route exact path="/contact" element={<Contact/>}/>

        <Route exact path="/contactforms" element={<ContactForms/>}/>
           <Route path="/contactforms/message" element={<Message/>}/>
           <Route path="/contactforms/callback" element={<Callback/>}/>
           <Route path="/contactforms/complaint" element={<Complaint/>}/>
       

        <Route exact path="/vertical/:verticalName/home/:verticalId" element={<Vertical/>}/>
        <Route exact path="/vertical/:verticalName/:verticalId/insights/:postType/:postId/:categoryId" element={<VBlog/>}/>
        <Route exact path="/vertical/:verticalName/service/:serviceName/:id/:verticalId/:categoryId" element={<Page/>}/>
        <Route exact path="/vertical/:verticalName/services/:serviceName/:id/:verticalId/:categoryId" element={<PageTwo/>}/>



        </Routes>

        <footer>
          <Footer/>
        </footer>
    </div>

  );
}

export default App;
