import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import reportsBanner from '../images/reports.jpg'
import Accordion from 'react-bootstrap/Accordion'


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Finance = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    const [financeData, setFinanceData] = useState([]);

    useEffect( () => {
   
        client
      .query({
        query: gql`
        query{
            pages {
                edges {
                  node {
                      financials {
                       downloadablefinance {
                        downloadurl
                        itemdescription
                        itemname
                    }
                }
                  }
                }
              }
      }
        `,
      })
      .then(async result => { 
            console.log(result.data.pages.edges)
            setFinanceData(result.data.pages.edges[0].node.financials.downloadablefinance)
      })
    
    
       
       }, [])



       let financeDataDetails

 if(financeData.length){ 

    financeDataDetails = financeData.map((financeDataDetails, i) => 
    <Accordion defaultActiveKey="0">
    <Accordion.Item eventKey={i}>
        <Accordion.Header>{financeDataDetails.itemname}</Accordion.Header>
           <Accordion.Body>
            <div className="col-12">
            {financeDataDetails.itemdescription}
            </div>
            <div className="col-12">

            <a href={financeDataDetails.downloadurl2}className='btn leadwayOrange whiteText noDecoration headerFont' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Download Now
          </a>
            </div>
         
          </Accordion.Body>
         </Accordion.Item>
       </Accordion>
    
    )
  }




  return (
    <>
           <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${reportsBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
              Finance Reports
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
           <div className="row"> 
             <div className="col-12">
             {financeDataDetails}
                </div> 
        </div>


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign headerFont' variant="secondary" >
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>

   
    </>
  );
};

export default Finance