import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import feedbackBanner from '../images/feedback.jpg';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Feedback = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
  
      
        let submitFinanceSchemeRequest = await fetch('https://lamwebservices-bc4dfe5fe774.herokuapp.com',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };






    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
              pages {
                   edges {
                     node {
                       team {
                         teamBannerImage {
                           mediaItemUrl
                         }
                         teamBannerText
                         teamBoard {
                           biography
                           firstName
                           jobRole
                           lastName
                           linkedinProfile
                           picture {
                             mediaItemUrl
                           }
                         }
                         teamProfesionals {
                           firstName
                           lastName
                           jobRole
                           biography
                           linkedinProfile
                           picture {
                             mediaItemUrl
                           }
                         }
                       }
                     }
                   }
                 }
       }
         `,
       })
       .then(async result => { 

       })
     
        
        }, [])




  return (
    <>
     <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${feedbackBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
              Feedback
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
         
         <div className="col-12 col-md-8 mx-auto top-text-clearance ">

         <form className='row mx-auto testDriveForm' onSubmit={handleSubmit(onSubmit)} >

<label className="bodyFont">
  Fullname
</label>
<input className='form-control  form-control-2' type="text" placeholder='Firstname' {...register("firstname", {required:true})}/>

<label className="bodyFont">
  Email
</label>
<input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />

<label className="bodyFont">
  Please share your feedback below
</label>
<input className='form-control form-control-2' type="textarea" placeholder='Your message' {...register("message", {required:true})}/>

<label className="bodyFont">
  Would you like a callback ?
</label>
<select className='form-control form-control-2' {...register("role", {required:true})}>
      <option value="none">Yes</option>
      <option value="Immediately">No</option>
</select>


<div className='col-12 col-md-12'>
<button  className="btn btn-primary leadwayOrange noDecoration headerFont" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>Submit</button>

</div>
</form>
         </div>


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign headerFont' variant="secondary" >
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>


   
    </>
  );
};

export default Feedback