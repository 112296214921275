import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import portalBanner from '../images/portal.jpg';



import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });

const Portal = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);

    const [portalData, setPortalData] = useState([]);




    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
          pages {
            edges {
              node {
                portals {
                  portals {
                    loginUrl
                    portalImage {
                      mediaItemUrl
                    }
                    portalName
                  }
                }
              }
            }
          }
       }
         `,
       })
       .then(async result => { 
         let professionalsArray = []
     
            console.log(result.data.pages.edges)
             setPortalData(result.data.pages.edges[2].node.portals.portals)
         
       })
     
        
        }, [])



        let portalDataDetails

        if(portalData.length){ 
       
          portalDataDetails = portalData.map((portalDataDetails, i) => 
   
          <div className="standard-banner-padding teamBoxMargin row" style={{backgroundImage:`url(${portalDataDetails.portalImage.mediaItemUrl})`, backgroundSize:"cover", backgroundPosition:"center"}}>
          <h1 className="headerFont heavyFont orangeText top-text-clearance-2 text-center"> 
            {portalDataDetails.portalName}
          </h1>
          <a href="#" data-target="_blank" className='btn btn-primary leadwayOrange btnDesign headerFont' style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>  Login  </a>
  
      </div>
           )
         }


  return (
    <>
           <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row teamBoxMargin " style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${portalBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
              Portals
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
        {portalDataDetails}


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign headerFont' variant="secondary">
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>

   
    </>
  );
};

export default Portal