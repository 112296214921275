import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import  bg from '../images/logo.png'
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faLinkedin, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons'

import waves from '../images/waves.png';

const Footer = () => {


    const [email, setEmail] = useState("");
  
    

    function emailUpdate(event){
        setEmail(event.target.value)
    }
    


    useEffect( () => {
    

     }, [])

    return(
        <>
     
<div className="row standard-padding-tb-rl whiteBackground fullWidth mOff">
    <div className="col-12 text-center standard-padding-tb">

     <img src={bg} width="12%" className='logoSizeFooter' /> 

    </div>

    <div className="col-12 text-center"> 
    
    <h6 className="bodyText thinFont"> SUBSCRIBE TO OUR NEWSLETTER  </h6>

    <div id="mc_embed_shell">

<div id="mc_embed_signup">
    <form action="https://gmail.us21.list-manage.com/subscribe/post?u=7f8ba29866898cc5ba80c4f97&amp;id=dd0d244430&amp;f_id=00c1d8e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate mx-auto" target="_blank">
        <div id="mc_embed_signup_scroll" className="row chimpCenter">
            <div class="mc-field-group col-7 col-md-9 pOff"><input type="email" name="EMAIL" onChange={emailUpdate} class="required email form-control footerForm" id="mce-EMAIL" placeholder="Your email address" required="" value={email}/></div>
            <div class="clear col-3 foot pOff">
           
                <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button btn btn-primary mb-3 footerBtn footerButtonMargin leadwayOrange standard-padding-tb-rl leftFloat" value="Subscribe"/>
                    </div>
        <div id="mce-responses" class="clear foot">
            <div class="response" id="mce-error-response" style={{display:"none"}}></div>
            <div class="response" id="mce-success-response" style={{display: "none"}}></div>
        </div>
    <div aria-hidden="true" style={{position: "absolute", left:"-5000px"}}>
        /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
        <input type="text" name="b_7f8ba29866898cc5ba80c4f97_dd0d244430" className='form-control footerForm' tabindex="-1" value=""/>
    </div>
        <div class="optionalParent">
            
        </div>
    </div>
</form>
</div>
</div>

    
    </div> 

    <div className="col-12 col-md-7 mx-auto">
    <div className="row fullWidth mOff centerMob">

    <div className="col-12 col-md-4 "> 

<h6 className=" headerFont "> Useful Links </h6>


<ul className="pOff"> 
<Link to="/resources/policies" className='noDecoration'>
    <li className="lightText footerLink noDecoration"> Terms & Conditions </li>
</Link>

<Link to="/resources/policies" className='noDecoration'>
    <li className="lightText footerLink noDecoration"> Code of Conduct </li>   
</Link>

<Link to="/resources/policies" className='noDecoration'>
    <li className="lightText footerLink noDecoration"> Whistle Blowing </li>   
</Link>

<Link to="/resources/portal" className='noDecoration'>  
    <li className="lightText footerLink noDecoration">  Investor Portal </li>  
</Link>

 

</ul>



</div>  

        <div className="col-12 col-md-4"> 

            <h6 className=" headerFont"> Why us </h6>


            <ul className="pOff"> 

            <Link to="/about" className='noDecoration'>
            <li className="lightText footerLink noDecoration"> About Us</li>
            </Link>
          
            <Link to="/resources/faq" className='noDecoration'> 
                <li className="lightText footerLink noDecoration"> FAQs </li> 
            </Link>

            <Link to="/careers" className='noDecoration'>
                <li className="lightText footerLink noDecoration">  Careers </li>
            </Link>

            <Link to="/contact" className='noDecoration'>
                <li className="lightText footerLink noDecoration">  Contact </li>  
            </Link>    
                 
            

            </ul>


        
        </div>  

        <div className="col-12 col-md-4"> 

<h6 className=" headerFont"> Solutions </h6>


<ul className="pOff"> 
<Link to="/vertical/individual-investor/home/cG9zdDoxMDI=" className='noDecoration'>
<li className="lightText footerLink noDecoration"> Indivdual Investor </li>  
</Link>

<Link to="/vertical/private-wealth/home/cG9zdDoxMDA=" className='noDecoration'>
<li className="lightText footerLink noDecoration">  Private Wealth </li> 
</Link>

<Link to="/vertical/institutional-investor/home/cG9zdDoxMDE=" className='noDecoration'> 
<li className="lightText footerLink noDecoration"> Institutional Investor </li> 
</Link>

<Link to="#" className='noDecoration'> 
<li className="lightText footerLink noDecoration">  LAM Mutual Funds </li> 
</Link>
   

</ul>



</div>  


<div className="col-12 text-center"> 

<h6 className=" headerFont"> Find Us on Socials  </h6>


<ul className="pOff"> 
    <li className="lightText"> <Link to="https://www.facebook.com/myleadwaywealth?mibextid=LQQJ4d" className='noDecoration footerLink '>   <FontAwesomeIcon icon={faFacebook}>  </FontAwesomeIcon> </Link>  <Link to="https://twitter.com/myleadwaywealth" className='noDecoration footerLink '>   <FontAwesomeIcon icon={faTwitter}> </FontAwesomeIcon></Link>  <Link to="https://instagram.com/myleadwaywealth" className='noDecoration footerLink '>  <FontAwesomeIcon icon={faInstagram}> </FontAwesomeIcon> </Link>  <Link to="https://www.linkedin.com/company/leadway-group/" className='noDecoration footerLink '>  <FontAwesomeIcon icon={faLinkedin}> </FontAwesomeIcon> </Link>  <Link to="https://www.youtube.com/c/leadwayassurance" className='noDecoration footerLink '>  <FontAwesomeIcon icon={faYoutube}> </FontAwesomeIcon> </Link> </li>
 

</ul>



</div>  

<div className="col-12 text-center">
    <p className='bodyText smallText'>
        Web Development & Content by iNspire
    </p>
</div>
        </div>

   
        </div> 

 
</div>
</>
    )

    
}

export default Footer;