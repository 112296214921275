import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import faqBanner from '../images/faq.jpg';
import Accordion from 'react-bootstrap/Accordion';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Faq = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    const [investingContent, setInvestingContent] = useState([]);
    const [fundingContent, setFundingContent] = useState([]);
    const [portfolioContent, setPortfolioContent] = useState([]);
    const [performanceContent, setPerformanceContent] = useState([]);
    const [glossaryContent, setGlossaryContent] = useState([]);
  



    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
            pages {
                edges {
                  node {
                    faq {
                      investing {
                        answer
                        question
                      }
                      fundaccount {
                        answer
                        question
                      }
                      glossary {
                        answer
                        question
                      }
                      performance {
                        answer
                        question
                      }
                      portfoliomanagement {
                        answer
                        question
                      }
                    }
                  }
                }
              }
       }
         `,
       })
       .then(async result => { 
    
              console.log(result.data.pages.edges)       
              setInvestingContent(result.data.pages.edges[6].node.faq.investing)
              setFundingContent(result.data.pages.edges[6].node.faq.fundaccount)
              setGlossaryContent(result.data.pages.edges[6].node.faq.glossary)
              setPerformanceContent(result.data.pages.edges[6].node.faq.performance)
              setPortfolioContent(result.data.pages.edges[6].node.faq.portfoliomanagement) 
              
       })
     
        
        }, [])


        let investingContentDetails
        let fundingContentDetails
        let glossaryContentDetails
        let performanceContentDetails
        let portfolioContentDetails

        if(investingContent.length){ 
       
           investingContentDetails = investingContent.map((investingContentDetails, i) => 
           <Accordion>
           <Accordion.Item eventKey={i}>
               <Accordion.Header>{investingContentDetails.question}</Accordion.Header>
                  <Accordion.Body>
                   <div className="col-12">
                   {investingContentDetails.answer}
                   </div>
                   <div className="col-12">
       
                   </div>
                
                 </Accordion.Body>
                </Accordion.Item>
              </Accordion>
           
           )
         }

         if(fundingContent.length){ 
       
         fundingContentDetails = fundingContent.map((fundingContentDetails, i) => 
          <Accordion>
          <Accordion.Item eventKey={i}>
              <Accordion.Header>{fundingContentDetails.question}</Accordion.Header>
                 <Accordion.Body>
                  <div className="col-12">
                  {fundingContentDetails.answer}
                  </div>
                  <div className="col-12">
      
                  </div>
               
                </Accordion.Body>
               </Accordion.Item>
             </Accordion>
          
          )
        }

        if(glossaryContent.length){ 
       
          glossaryContentDetails = glossaryContent.map((glossaryContentDetails, i) => 
          <Accordion >
          <Accordion.Item eventKey={i}>
              <Accordion.Header>{glossaryContentDetails.question}</Accordion.Header>
                 <Accordion.Body>
                  <div className="col-12">
                  {glossaryContentDetails.answer}
                  </div>
                  <div className="col-12">
      
                  </div>
               
                </Accordion.Body>
               </Accordion.Item>
             </Accordion>
          
          )
        }

        if(performanceContent.length){ 
       
          performanceContentDetails = performanceContent.map((performanceContentDetails, i) => 
          <Accordion>
          <Accordion.Item eventKey={i}>
              <Accordion.Header>{performanceContentDetails.question}</Accordion.Header>
                 <Accordion.Body>
                  <div className="col-12">
                  {performanceContentDetails.answer}
                  </div>
                  <div className="col-12">
      
                  </div>
               
                </Accordion.Body>
               </Accordion.Item>
             </Accordion>
          
          )
        }

        if(portfolioContent.length){ 
       
          portfolioContentDetails = portfolioContent.map((portfolioContentDetails, i) => 
          <Accordion>
          <Accordion.Item eventKey={i}>
              <Accordion.Header>{portfolioContentDetails.question}</Accordion.Header>
                 <Accordion.Body>
                  <div className="col-12">
                  {portfolioContentDetails.answer}
                  </div>
                  <div className="col-12">
      
                  </div>
               
                </Accordion.Body>
               </Accordion.Item>
             </Accordion>
          
          )
        }
       


  return (
    <>
                    <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${faqBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
              FAQ's
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
      
           <div className="row"> 
             <div className="col-12">

             <Accordion>
                 <Accordion.Item eventKey="invest">
                    <Accordion.Header> Getting started with Investing </Accordion.Header>
                      <Accordion.Body>
                          <div className="col-12">
                          {investingContentDetails}
                          </div>
          
                   </Accordion.Body>
                 </Accordion.Item>
                 <Accordion.Item eventKey="fund">
                    <Accordion.Header> Funding Your Account </Accordion.Header>
                      <Accordion.Body>
                          <div className="col-12">
                          {fundingContentDetails}
                          </div>
          
                   </Accordion.Body>
                 </Accordion.Item>

                 <Accordion.Item eventKey="portfolio">
                    <Accordion.Header> Portfolio Management Practice </Accordion.Header>
                      <Accordion.Body>
                          <div className="col-12"> 
                             {portfolioContentDetails}
                          </div>
          
                   </Accordion.Body>
                 </Accordion.Item>

                 <Accordion.Item eventKey="performance">
                    <Accordion.Header> Fund Performance  </Accordion.Header>
                      <Accordion.Body>
                          <div className="col-12">
                             {performanceContentDetails}
                          </div>
          
                   </Accordion.Body>
                 </Accordion.Item>
                 <Accordion.Item eventKey="gloss">
                    <Accordion.Header> Glossary </Accordion.Header>
                      <Accordion.Body>
                          <div className="col-12">
                          {glossaryContentDetails}
                          </div>
          
                   </Accordion.Body>
                 </Accordion.Item>
             </Accordion>
                   
                 </div> 
          </div>

            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign headerFont' variant="secondary">
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>

   
    </>
  );
};

export default Faq