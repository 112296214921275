import React, { useState, useEffect, Component } from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import {useParams, Link} from 'react-router-dom';
import  bg from '../images/logo.png'
import table from '../images/table.jpg';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import waves from '../images/waves.png';
import Navigation from './nav';
import calculator from '../images/calculator.jpg';
import advise from '../images/advise.jpg';
import investment from '../images/investment.jpg';
import docs from '../images/docs.jpg';
import stad from '../images/tham.jpeg';
import ls from '../images/lifestyle.jpeg';
import fam from '../images/fami.jpg';
import fbiz from '../images/fbiz.jpg';
import philantropy from '../images/philantropy.jpg';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });



const Page = (props) => {



    const [privateWealth, setPrivateWealth] = useState(false)
    const [indivdualInvestor, setIndividualInvestor] = useState(false)
    const [institutionalInvestor, setInstitutionalInvestor]= useState(false)
    const [solutionsName, setSolutionsName] = useState("")
    const [solutionsSummary, setSolutionsSummary]= useState("")
    const [solutionsDescription, setSolutionsDescription]= useState("")
    const [solutionsHeader, setSolutionsHeader]= useState("")
    const [solutionsCoverImage, setSolutionsCoverImage]= useState("")
    const [solutionsServices, setSolutionsServices]= useState([])
    const [solutions, setSolutions] = useState([])

    
    const params = useParams()

    let vertical = params.verticalName
    let verticalSanitized = vertical.replace("-", " ")

    let verticalCategory = parseInt(params.categoryId)
    let categoryId = verticalCategory

    useEffect( () => {

      client
  .query({
    query: gql`
    query($id:ID!) {
      wealthBy(id:$id) {
        id
        solutions {
          fieldGroupName
          solutionDescription
          solutionHeader
          solutionName
          solutionSummary
          solutionCoverImage{
            mediaItemUrl
          }
          services {
            fieldGroupName
            serviceDescription
            serviceName
            serviceImage{
              mediaItemUrl
            }
          }
        }
      }
}
 
    `,
    variables: {id:params.id}
    })
  .then(async result => {

     console.log(result.data.wealthBy.solutions)
     setSolutionsName(result.data.wealthBy.solutions.solutionName)
     setSolutionsSummary(result.data.wealthBy.solutions.solutionSummary)
     setSolutionsHeader(result.data.wealthBy.solutions.solutionHeader)
     setSolutionsCoverImage(result.data.wealthBy.solutions.solutionCoverImage.mediaItemUrl)
     setSolutionsDescription(result.data.wealthBy.solutions.solutionDescription)
      
     let serviceArray = []
    

   
    for( let i=0; i <= result.data.wealthBy.solutions.services.length - 1; i++){
    console.log(result.data.wealthBy.solutions.services.length)
    let serviceDetailPack =[{serviceName:result.data.wealthBy.solutions.services[i].serviceName, serviceImage:result.data.wealthBy.solutions.services[i].serviceImage.mediaItemUrl, serviceDescription: result.data.wealthBy.solutions.services[i].serviceDescription}]
         
     serviceArray.push(serviceDetailPack)
      console.log(serviceArray)
    await setSolutionsServices(serviceArray)
    console.log(solutionsServices)
     }

   
  })


  //get all solutions for category


  client
  .query({
    query: gql`
    query($id: Int!) {
         allWealth(where:{categoryId:$id}) {
      edges {
        node {
          id
          solutions {
            solutionName
            solutionSummary
            solutionCoverImage{
              mediaItemUrl
            }
          }
        }
      }
    }
  }
  `,
  variables: {id:verticalCategory}
  })
  .then(async result => {

     console.log(result.data.allWealth)
      
     let solutionsArray = []

     
     for( let i=0; i <= result.data.allWealth.edges.length - 1; i++){
    
      if(params.serviceName != result.data.allWealth.edges[i].node.solutions.solutionName ) {
     let solutionsDetailPack =[{solutionName:result.data.allWealth.edges[i].node.solutions.solutionName, solutionImage:result.data.allWealth.edges[i].node.solutions.solutionCoverImage.mediaItemUrl,solutionSummary:result.data.allWealth.edges[i].node.solutions.solutionSummary, solutionId: result.data.allWealth.edges[i].node.id}]
          
      solutionsArray.push(solutionsDetailPack)
      
     await setSolutions(solutionsArray)
      
      console.log(solutions)
      }
     }
    
 
  })



  function identifyVertical() {

      if (params.verticalName === "institutional-investor"){
           console.log("III")
          setInstitutionalInvestor(true)
      }


      if (params.verticalName === "individual-investor"){
          setIndividualInvestor(true)
          console.log("II")
      }


      if (params.verticalName === "private-wealth"){
          setPrivateWealth(true)
          console.log("pw")
      }
    }

    identifyVertical()
    window.scrollTo(0, 0)
 }, [])
    
    
 let servicesListingDetail

 if(solutionsServices.length){ 
  console.log(solutionsServices)
     servicesListingDetail = solutionsServices.map( (servicesListingDetail, i) =>

     i % 2 === 0 ?      
      <div className="col-12 col-md-10 mx-auto marginGlass2"> 
          

       <div className="row greyShadow fullWidth mOff"> 

        <div className="col-12 col-md-7 mediaThrill" style={{backgroundImage:`url(${servicesListingDetail[0].serviceImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>

        </div>

   <div className="col-12 col-md-5 businessDescriptionPadding text-center whiteBackground">
       <h3 className="headerFont orangeText "> {servicesListingDetail[0].serviceName} </h3>
     <p className='bodyFont greyText'>
     {servicesListingDetail[0].serviceDescription}
         </p>
     <a href="/contact" target="_blank" className="btn btn-primary leadwayOrange btnDesign headerFont"> Talk to us </a>
 
   </div>
 
 </div> 


</div> : 

<div className="col-12 col-md-10 mx-auto marginGlass2"> 
     

  <div className="row greyShadow fullWidth mOff"> 



<div className="col-12 col-md-5 businessDescriptionPadding text-center whiteBackground">
  <h3 className="headerFont orangeText solutionNamePad solutionNameSize"> {servicesListingDetail[0].serviceName} </h3>
<p className='bodyFont greyText'>
{servicesListingDetail[0].serviceDescription}
    </p>
<a href="/contact" target="_blank" className="btn btn-primary leadwayOrange btnDesign headerFont"> Talk to us </a>

</div>

<div className="col-12 col-md-7 mediaThrill" style={{backgroundImage:`url(${servicesListingDetail[0].serviceImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>

</div>

</div> 


</div>

   
         
      )
   
     
   }



   let solutionsListingDetail

   if(solutions.length){ 
    console.log(solutions)
       solutionsListingDetail = solutions.map((solutionsListingDetail, i) => 


       <div className="col-12 col-md-3 pOff mBMobile">
       <Card className="text-center vertical-image-height"  style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${solutionsListingDetail[0].solutionImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
       <div className="standard-padding-tb-2">
       <Card.Title className="text-center standard-padding-tb-2 standard-padding-rl-3 vertical-text-positon">
           <h4 className="whiteText headerFont solutionNamePad solutionNameSize"> {solutionsListingDetail[0].solutionName} </h4>
       </Card.Title>
       </div>
     <Card.Body>
     <div className='col-12 whiteText vertical-text-box-height'>
          <h6 className={"whiteText bodyFont solutionsText"+i}>
          {solutionsListingDetail[0].solutionSummary}
          </h6>
        
         </div>
       <a href={`/vertical/${params.verticalName}/service/${solutionsListingDetail[0].solutionName}/${solutionsListingDetail[0].solutionId}/${params.verticalId}/${categoryId}`} target="_blank" className="btn btn-primary leadwayOrange btnDesign headerFont">Learn More</a>

     </Card.Body>
   </Card>
       </div>

     
     
           
        )
     
       
     }




    return(
        <>

<Navigation currentVertical={params.verticalName} currentVerticalId={params.verticalId} currentCategory={params.categoryId} />

<div className="standard-banner-padding-top-vertical row fullWidth mOff" style={{backgroundImage:`url(${solutionsCoverImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
<div className= {`standard-banner-padding-vertical ${privateWealth ? 'greyBackground' : indivdualInvestor ? 'orangeBackground' : institutionalInvestor ? 'orangeBackground': false }`}> 
      <div className="col-12 text-center"> 
          <h2 className="whiteText lightFont">
               {solutionsName}
          </h2>
          <hr className='barStyle mx-auto' />
         <h6 className="whiteText"> {solutionsSummary}</h6>

      </div>
         </div>
</div>


<div className="" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
     <div className='col-12 col-md-10 mx-auto standard-padding-top glassBackground'> 
        <h2 className="headerFont text-center greyText2 mOff"> 
        {solutionsHeader}
        </h2>
        <hr className='barStyleGP mx-auto mBOff' />
     </div>

     <div className="col-12 col-md-10 mx-auto standard-padding-rl-2 standard-padding-tb-2 glassBackground text-center">
    <p className="bodyFont"> 
        {solutionsDescription}
        </p>
        </div>
     <div className=""> 




{servicesListingDetail}


<div className="row text-center teamBoxMargin fullWidth mOf marginGlass2">
    <div className="col-12">
    <Link to={`/vertical/${params.verticalName}/home/${params.verticalId}`} className="btn btn-primary leadwayOrange btnDesign2 headerFont" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>Back to {verticalSanitized}</Link>

    </div>
</div>



<div className="col-12 standard-padding-tb"> 

<h4 className="headerFont text-center greyText2"> 
      You might also be interested in
   </h4>
   <hr className='barStyleGP mx-auto' />

</div>


     <div className="col-12 col-md-10 mx-auto vertical-padding-bottom"> 
      <div className="row fullWidth mOff" id="flex1">
    {solutionsListingDetail}




      </div>


    

     
     </div>



    
     </div>
</div>













</>
    )

    
}

export default Page;