import React, { useState, useEffect, Component } from 'react';
import {useParams, Link} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
import Modal from 'react-bootstrap/Modal';
import  bg from '../images/logo.png'
import pw from '../images/cor.jpg';
import hni from '../images/hni.jpg';
import ind from '../images/ind.jpg';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faCaretRight, faSearch } from '@fortawesome/free-solid-svg-icons'
import waves from '../images/waves.png';
import Navigation from './nav';
import calculator from '../images/calculator.jpg';
import advise from '../images/advise.jpg';
import investment from '../images/investment.jpg';
import docs from '../images/docs.jpg';
import stad from '../images/tham.jpeg';
import ls from '../images/lifestyle.jpeg';



import{InMemoryCache,
    ApolloClient,
    gql
    } from '@apollo/client'
import { Nav } from 'react-bootstrap';
    
    const client = new ApolloClient({
      cache:  new InMemoryCache(),
      uri: "https://lam-cms.ford.com.ng/graphql",
      fetchOptions: {
        mode: 'no-cors',
      }
    });


  

const Vertical = (props) => {


    const params = useParams()

    let vertical = params.verticalName
    let verticalSanitized = vertical.replace("-", " ")
  
    const [privateWealth, setPrivateWealth] = useState(false)
    const [indivdualInvestor, setIndividualInvestor] = useState(false)
    const [institutionalInvestor, setInstitutionalInvestor]= useState(false)
    const [verticalCoverCopy, setVerticalCoverCopy] = useState("")
    const [verticalCoverSubcopy, setVerticalCoverSubcopy] = useState("")
    const [verticalDescription, setVerticalDescription] = useState("")
    const [verticalImage, setVerticalImage] = useState("")
    const [verticalName , setVerticalName] = useState("")
    const [infoCardRegion, setInfoCardRegion] = useState([])
    const [solutions, setSolutions] = useState([])
    const [solutionsVideo, setSolutionsVideo] = useState([])
    const [categoryId, setCategoryId ] = useState("")
    const [storeVerticalPreference, setStoreVerticalPreference] = useState(false)
    const [switchVerticalPreference, setSwitchVerticalPreference] = useState(false)
    const [verticalParameter , setVerticalParameter] = useState("")
    const [switchImage, setSwitchImage] = useState("")
    const [portfolioImage, setPortfolioImage] = useState("")
    

    function closeModal(){

         setStoreVerticalPreference(false)
         setSwitchVerticalPreference(false)
    }

    let categoryid
    useEffect( () => {

      client
      .query({
        query: gql`
       query($id:ID!){
        verticals(id:$id) {

          categories {
            edges {
              node {
                categoryId
                name
              }
            }
          }
            vertical {
              
              fieldGroupName
              infoCardRegion {
                sidePanelDescription
                sidePanelName
                videoLink
                sidePanelImage {
                  mediaItemUrl
                }
              }
              verticalCoverCopy
              verticalCoverSubcopy
              verticalDescription
              verticalImage {
                mediaItemUrl
              }
              verticalName
            }
          }
        }
   
      `,
      variables: {id:params.verticalId}
      })
    .then(async result => {

        categoryid = result.data.verticals.categories.edges[0].node.categoryId

     await   setVerticalImage(result.data.verticals.vertical.verticalImage.mediaItemUrl)
     await    setVerticalCoverCopy(result.data.verticals.vertical.verticalCoverCopy)
     await  setVerticalDescription(result.data.verticals.vertical.verticalDescription)
     await  setVerticalName(result.data.verticals.vertical.verticalName)
     await setVerticalCoverSubcopy(result.data.verticals.vertical.verticalCoverSubCopy)
        await   setCategoryId(result.data.verticals.categories.edges[0].node.categoryId)



     if(categoryid){
   console.log(categoryid)
      client
      .query({
        query: gql`
        query($id: Int!) {
             allWealth(where:{categoryId:$id}) {
          edges {
            node {
              id
              solutions {
                solutionName
                solutionSummary
                solutionCoverImage{
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
      `,
      variables: {id:categoryid}
      })
      .then(async result => {

         console.log(result.data.allWealth)
          
         let solutionsArray = []

          if (solutionsArray.length < 4) { 
         for( let i=0; i <= result.data.allWealth.edges.length - 1; i++){
        
         let solutionsDetailPack =[{solutionName:result.data.allWealth.edges[i].node.solutions.solutionName, solutionImage:result.data.allWealth.edges[i].node.solutions.solutionCoverImage.mediaItemUrl, solutionSummary: result.data.allWealth.edges[i].node.solutions.solutionSummary, solutionId: result.data.allWealth.edges[i].node.id}]
              
          solutionsArray.push(solutionsDetailPack)
          
         await setSolutions(solutionsArray)
          console.log(solutions)
         }
        
        }
      })


      client
      .query({
        query: gql`
        query($id: Int!) {
             allInstitutional(where:{categoryId:$id}) {
              edges {
                node {
                  id
                }
              }
              nodes {
                solutionVideo {
                  videoTile {
                    fieldGroupName
                    solutionDescription
                    solutionHeader
                    solutionName
                    solutionSummary
                    videoLink
                    videoName
                    solutionImage{
                        mediaItemUrl
                    }
                  }
                }
              }
            }
          }
      `,
      variables: {id:categoryid}
      })
      .then(async result => {
         console.log(result.data.allInstitutional.nodes[0].solutionVideo.videoTile[0])

         let solutionsVideoArray = []

       
         for( let i=0; i <= result.data.allInstitutional.nodes[0].solutionVideo.videoTile.length - 1; i++){
        
         let solutionsVideoDetailPack =[{solutionVideoName:result.data.allInstitutional.nodes[0].solutionVideo.videoTile[0].solutionName, solutionVideoImage:result.data.allInstitutional.nodes[0].solutionVideo.videoTile[0].solutionImage.mediaItemUrl, solutionVideoSummary: result.data.allInstitutional.nodes[0].solutionVideo.videoTile[0].solutionSummary, solutionVideoId: result.data.allInstitutional.edges[i].node.id, videoName:result.data.allInstitutional.nodes[0].solutionVideo.videoTile[0].videoName, videoUrl:result.data.allInstitutional.nodes[0].solutionVideo.videoTile[i].videoLink}]
              
          solutionsVideoArray.push(solutionsVideoDetailPack)
          
         await setSolutionsVideo(solutionsVideoArray)
          console.log(solutionsVideo)
         }
        
     
      })

      }

    })


    function identifyVertical() {

        if (params.verticalName === "institutional-investor"){
             console.log("III")
            setInstitutionalInvestor(true)
            setPortfolioImage(pw)
        }


        if (params.verticalName === "individual-investor"){
            setIndividualInvestor(true)
            console.log("II")
            setPortfolioImage(ind)

        }


        if (params.verticalName === "private-wealth"){
            setPrivateWealth(true)
            console.log("pw")
            setPortfolioImage(hni)
        }
      }

      identifyVertical()
      window.scrollTo(0, 0)





   





     async function  getPersonalizationData(){ 

      console.log("ran")
      let personalizationData = await fetch('https://charisbrain.herokuapp.com/charis/lamwebsiteservices/personalisation/retrieveverticalData',{ 
        method: "POST",
        headers:{
          'Content-Type':'application/JSON',
          'Accept': 'application/JSON'
        },
        mode:'cors',
        credentials:'include'  
      })
    
      .then(response => response.json())
      .then(response => { 
          console.log(response)
  
          if (!response){
            setStoreVerticalPreference(true)
          }
  
          if(response){
            let parameter
            if(response === "1"){
              setVerticalParameter("private wealth")
                parameter = "private wealth"
              setSwitchImage(hni)
            }else if(response === "2"){
              setVerticalParameter("institutional investor")
               parameter = "institutional investor"
              setSwitchImage(pw)
            }else if(response === "3"){
               setVerticalParameter("individual investor")
                parameter = "individual investor"
               setSwitchImage(ind)
            }
            if(parameter !== verticalSanitized){
            setSwitchVerticalPreference(true)
            }
          }
      
      })
     }
  
    getPersonalizationData()


   }, [])


   async function sendVerticalPreference(){ 

    let preference

    if(privateWealth){

       preference = {
        verticalPreference:1
      }
     console.log("a")
    }

    if(institutionalInvestor){
      preference = {
        verticalPreference:2
      }
     console.log("b")
    }

    if(indivdualInvestor){
      preference = {
        verticalPreference:3
      }

     console.log("c")
    }
   
       console.log(preference)
   
     const submitVariantHistoryData = await fetch('https://charisbrain.herokuapp.com/charis/lamwebsiteservices/personalisation/verticalData',{ 
   
     method:"POST",
     headers:{ 
     "content-type" : "application/json"
     },
     body: JSON.stringify(preference),
     mode:"cors",
     credentials:"include"
   
     })
     .then(response => response.json())
     .then(response => { 
       console.log('response ran')
         console.log(response)  
         setStoreVerticalPreference(false)
         setSwitchVerticalPreference(false)
     })

    }

    function controlSolutionsText(x){ 
   

    alert($(this).id)
    $(".solutionsText").fadeOut()

        
 
      
    }

    //controlSolutionsText()

   
  
   let solutionsListingDetail

   if(solutions.length){ 
    console.log(solutions)
       solutionsListingDetail = solutions.map((solutionsListingDetail, i) => 
   
       <div className="col-12 col-md-4 mBMobile">
       <Card className={"text-center solutions"} id={i} onHover={controlSolutionsText} style={{ width: '100%' }}>
       <div className="standard-padding-tb-2 vertical-image-height" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${solutionsListingDetail[0].solutionImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
       <Card.Title className="text-center standard-padding-tb-2 standard-padding-rl-3 vertical-text-positon">
           <h3 className="whiteText headerFont solutionNamePad"> {solutionsListingDetail[0].solutionName} </h3> 
           <hr className='barStyle mx-auto' />

         <div className='col-12 whiteText vertical-text-box-height'>
          <h6 className={"whiteText bodyFont solutionsText"+i}>
          {solutionsListingDetail[0].solutionSummary}
          </h6>
        
         </div>
         
      <div className="col-12">
        <Link to={`/vertical/${params.verticalName}/service/${solutionsListingDetail[0].solutionName}/${solutionsListingDetail[0].solutionId}/${params.verticalId}/${categoryId}`} className="btn btn-primary leadwayOrange btnDesign headerFont" >Learn More</Link>
       
       </div>
          
       </Card.Title>
       </div>

    </Card>
       </div>
     
     
           
        )
     
       
     }


     


     let solutionsVideoListingDetail

     if(solutionsVideo.length){ 
      console.log(solutionsVideo)
         solutionsVideoListingDetail = solutionsVideo.map((solutionsVideoListingDetail, i) => 
     
         <div className="row greyShadow fullWidth mOff"> 

         <div className="col-12 col-md-8 pOff" >


          <iframe width="100%" height="100%" src={solutionsVideoListingDetail[0].videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

     
     
         </div>
     
         <div className="col-12 col-md-4 pOff">
     
         <Card className="text-center borderControl" style={{ width: '100%' }}>
             <div className="standard-padding-tb-2 vertical-image-height" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)), url(${solutionsVideoListingDetail[0].solutionVideoImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             <Card.Title className="text-center standard-padding-tb-2 standard-padding-rl-3 vertical-text-positon">
                 <h3 className="whiteText headerFont"> {solutionsVideoListingDetail[0].solutionVideoName} </h3> 
             </Card.Title>
             </div>
           <Card.Body>
             
             <Card.Text className="standard-padding-tb-3">
            {solutionsVideoListingDetail[0].solutionVideoSummary} </Card.Text>
             <Link to={`/vertical/${params.verticalName}/services/${solutionsVideoListingDetail[0].solutionVideoName}/${solutionsVideoListingDetail[0].solutionVideoId}/${params.verticalId}/${categoryId}`} className="btn btn-primary leadwayOrange btnDesign headerFont">Learn More</Link>
     
           </Card.Body>
         </Card>
             
         </div>
         
         </div> 
       
       
             
          )
       
         
       }


       if(categoryId){

     

    return(
        <>
  <Navigation currentVertical={params.verticalName} currentVerticalId={params.verticalId} currentCategory={categoryId} />

<div className="standard-banner-padding-top-vertical row fullWidth mOff" style={{backgroundImage:`url(${verticalImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>

   <div className= {`standard-banner-padding-vertical ${privateWealth ? 'greyBackground' : indivdualInvestor ? 'orangeBackground' : institutionalInvestor ? 'orangeBackground': false }`}> 
      <div className="col-12 text-center"> 
          <h3 className="whiteText lightFont">
                 {verticalCoverCopy}
          </h3>
          <hr className='barStyleHeading mx-auto' />
        <h6 className="whiteText"> {verticalCoverSubcopy} </h6>

      </div>
         </div>
</div>


<div className="" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}> 
     <div className='col-12 col-md-10 mx-auto standard-padding-top glassBackground'> 
        <h2 className="headerFont text-center greyText2 mOff"> 
           {verticalName}
        </h2>
        <hr className='barStyleGP mx-auto mBOff' />
     </div>

     <div className="col-12 col-md-10 mx-auto standard-padding-rl-2 standard-padding-tb-2 glassBackground text-center">
    <p className="bodyFont"> 
    {verticalDescription}    
    </p>
     </div>

     <div className=""> 

     <div className="col-12 standard-padding-tb-unequal"> 

     <h4 className="headerFont text-center greyText2"> 
           Solutions
        </h4>
        <hr className='barStyleGP mx-auto' />
     
     </div>
     <div className="col-12 col-md-10 mx-auto vertical-padding-bottom"> 
      <div className="row fullWidth mOff" id="flex1">


       {solutionsListingDetail}

      </div>


    

     
     </div>
    
     </div>
</div>



<div className="col-12 col-md-10 mx-auto mediaThrillPosition mBMobile"> 
 

{solutionsVideoListingDetail}


</div>





           <Modal show={storeVerticalPreference}>
        
        <Modal.Body className="blackBg pOff-tb">

         <div className="row">
         <div className="col-12 col-md-8 profile" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${portfolioImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
               <h4 className="headerFont teamDetailsDesign ">  <span className="bodyFont teamFirstNameDesign">  </span></h4>
               
                 <p className="whiteText teamFirstNameDesign">  </p>
                 <a href="#" target="_blank" >
             
               </a>
         </div>  
               <div className="col-12 col-md-4 text-center standard-padding-tb"> 
         <h6 className="bodyFont orangeText mBOff"> An experience designed for you! </h6>
         <h1 className="bodyFont whiteText"> My LAM & I </h1>
         <div className="paddingTBRL" > 
         <p className="bodyFont whiteText ">
           Our website is designed to be tailored to the specific needs
           of each of our customers. Use the button below to save {verticalSanitized} as your portfolio type for a personalised experience
          </p>  

          <Link onClick={sendVerticalPreference} className='btn leadwayOrange whiteText noDecoration headerFont' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Save Customer Settings
          </Link>
          <Link onClick={closeModal} className='btn leadwayOrange whiteText noDecoration headerFont margin-top-vertical' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Maybe Later
          </Link>
         </div>           
               </div>
         </div>
                  
            </Modal.Body>
    
       
       

      </Modal>


      <Modal show={switchVerticalPreference}>
        
        <Modal.Body className="blackBg pOff-tb">

         <div className="row">
         <div className="col-12 col-md-8 profile" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${switchImage})`, backgroundSize:"cover", backgroundPosition:"center"}}>
               <h4 className="headerFont teamDetailsDesign ">  <span className="bodyFont teamFirstNameDesign">  </span></h4>
               
                 <p className="whiteText teamFirstNameDesign">  </p>
                 <a href="#" target="_blank" >
             
               </a>
         </div>  
               <div className="col-12 col-md-4 text-center standard-padding-tb"> 
         <h6 className="bodyFont orangeText mBOff"> An experience designed for you! </h6>
         <h1 className="bodyFont whiteText"> My LAM & I </h1>
         <div className="paddingTBRL" > 
         <p className="bodyFont whiteText ">
          Dear Esteemed, <br/>You are currently recognised under our {verticalParameter} portfolio. Would you like to switch
           your personalisation settings to {verticalSanitized} 
          </p>  

          <Link onClick={sendVerticalPreference} className='btn leadwayOrange whiteText noDecoration headerFont' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Yes, Change Customer Settings
          </Link>
          <Link onClick={closeModal} className='btn leadwayOrange whiteText noDecoration headerFont margin-top-vertical' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Maybe Later
          </Link>
       
         </div>
        
            
              
            
               </div>
         </div>
            
      


            
            </Modal.Body>
     
      </Modal>


</>
    )

       }else{

        return(
          <div> 
            
          </div>
        )
       }


}

export default Vertical;