import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });


const Profile = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    const [boardLamContent, setboardLamContent] = useState([]);
    const [teamProfessionalContent, setteamProfessionalContent] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [bio, setBio] = useState("");
    const [linkedinProfile, setLinkedinProfile] = useState("");
    const [role, setRole] = useState("");
    const [picture, setPicture] = useState("");




    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
              pages {
                   edges {
                     node {
                      people {
                        bannerImageTeam{
                          mediaItemUrl
                        }
                        bannerTextTeam
                        fieldGroupName
                      boardLam {
                        bio
                        fieldGroupName
                        firstName
                        jobrole
                        lastName
                        linkedin
                        headshot{
                          mediaItemUrl
                        }
                      }
                        lamProfessionals {
                          bio
                          fieldGroupName
                          firstname
                          jobRole
                          lastname
                          linkedinProfile
                          headshot{
                            mediaItemUrl
                          }
                        }
                      }
                     }
                   }
                 }
       }
         `,
       })
       .then(async result => { 
         let professionalsArray = []
     
             console.log(result.data.pages.edges)
             
        
              setboardLamContent(result.data.pages.edges[7].node.people.boardLam)
              setteamProfessionalContent(result.data.pages.edges[7].node.people.lamProfessionals)

              for( let i=0; i <= result.data.pages.edges[7].node.people.lamProfessionals.length - 1; i++){
                console.log(params.id)
                console.log(params.id.length)
                console.log(result.data.pages.edges[7].node.people.lamProfessionals[i].firstname)
                
              let  firstname = result.data.pages.edges[7].node.people.lamProfessionals[i].firstname
              let fname =  firstname.replace(/\s/g,"")
                console.log(fname.split())
                 
                  if (fname === params.id){
                    console.log("rann")

                    setFirstName(result.data.pages.edges[7].node.people.lamProfessionals[i].firstname)
                    setLastName(result.data.pages.edges[7].node.people.lamProfessionals[i].lastname)
                    setRole(result.data.pages.edges[7].node.people.lamProfessionals[i].jobRole)
                    setLinkedinProfile(result.data.pages.edges[7].node.people.lamProfessionals[i].linkedinProfile)
                    setBio(result.data.pages.edges[7].node.people.lamProfessionals[i].bio)
                    setPicture (result.data.pages.edges[7].node.people.lamProfessionals[i].headshot.mediaItemUrl)

                    return true
                   
                  }

                   }
                   if(!found){

                    for( let i=0; i <= result.data.pages.edges[7].node.people.boardLam.length - 1; i++){
                      console.log(params.id)
                      console.log(params.id.length)
                      console.log(result.data.pages.edges[7].node.people.boardLam[i].firstName)
                      
                    let  firstname = result.data.pages.edges[7].node.people.boardLam[i].firstName
                    let fname =  firstname.replace(/\s/g,"")
                      console.log(fname.split())
                       
                        if (firstname === params.id){
                          console.log("rann")
      
                          setFirstName(result.data.pages.edges[7].node.people.boardLam[i].firstName)
                          setLastName(result.data.pages.edges[7].node.people.boardLam[i].lastName)
                          setRole(result.data.pages.edges[7].node.people.boardLam[i].jobRole)
                          setLinkedinProfile(result.data.pages.edges[7].node.people.boardLam[i].linkedin)
                          setBio(result.data.pages.edges[7].node.people.boardLam[i].bio)
                          setPicture (result.data.pages.edges[7].node.people.boardLam[i].headshot.mediaItemUrl)
      
                          return true
                         
                        }
      
                         }

                    
                  }
       })
     
        
        }, [])




  return (
    <>
           <Modal show={show}>
        
        <Modal.Body className="">

         <div className="row">
         <div className="col-12 col-md-4 profile" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${picture})`, backgroundSize:"cover", backgroundPosition:"center"}}>
               <h4 className="headerFont teamDetailsDesign "> {firstName} <span className="bodyFont teamFirstNameDesign"> {lastName} </span></h4>
                 <hr className='barStyle mOff' />
                 <p className="whiteText teamFirstNameDesign"> {role} </p>
                 <a href={linkedinProfile} target="_blank" >
                <img src={linkedIn} width="5%"/> 
               </a>
         </div>  
               <div className="col-12 col-md-8"> 
         <p className="bodyFont">
            {bio}
              </p>
               </div>
         </div>
            
      


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/team"className='btn leadwayOrange whiteText noDecoration headerFont' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
             Back to team
          </Link>
       
        </Modal.Footer>
      </Modal>

   
    </>
  );
};

export default Profile