import { Routes, Route, Link, Outlet, useParams } from 'react-router-dom';
import React, { useState, useEffect, Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import e1 from '../images/e1.jpg';
import pressBanner from '../images/press.jpg'
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import Accordion from 'react-bootstrap/Accordion'


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'
  
  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });

const Press = () => { 

  const params = useParams()

    const [show, setShow] = useState(true);
    const [found, setFound] = useState(false);


    const [pressData, setPressData] = useState([]);




    useEffect( () => {
   
         client
       .query({
         query: gql`
         query{
            pages {
                edges {
                  node {
                    presskit {
                      fieldGroupName
                      downloadableassets {
                        downloadurl
                        fieldGroupName
                        itemdescription
                        itemname
                      }
                    }
                  }
                }
              }
      }
         `,
       })
       .then(async result => { 
       
        console.log(result.data.pages.edges)
        setPressData(result.data.pages.edges[4].node.presskit.downloadableassets)

       })
     
        
        }, [])





       let pressDataDetails

       if(pressData.length){ 
      
          pressDataDetails = pressData.map((pressDataDetails, i) => 
          <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey={i}>
              <Accordion.Header>{pressDataDetails.itemname}</Accordion.Header>
                 <Accordion.Body>
                  <div className="col-12">
                  {pressDataDetails.itemdescription}
                  </div>
                  <div className="col-12">
      
                  <a href={pressDataDetails.downloadurl2}className='btn leadwayOrange whiteText noDecoration headerFont' variant="secondary" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
                   Download Now
                </a>
                  </div>
               
                </Accordion.Body>
               </Accordion.Item>
             </Accordion>
          
          )
        }

  return (
    <>
   <Modal className="resourceLayout" show={show}>
        
        <Modal.Body className="">

        <div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.3)),url(${pressBanner})`, backgroundSize:"cover", backgroundPosition:"center"}}>
           <h1 className="headerFont heavyFont orangeText text-center top-text-clearance-2"> 
             Press
            </h1>
            <hr className='barStyle mx-auto' />   
           </div>
            
        <div className="row"> 
             <div className="col-12">
             {pressDataDetails}
                </div> 
        </div>


            
            </Modal.Body>
        <Modal.Footer>
          <Link to="/resources"className='btn btn-primary btnDesign headerFont' variant="secondary">
             Back to Resources
          </Link>
       
        </Modal.Footer>
      </Modal>
   
    </>
  );
};

export default Press