import React, { useState, useEffect, Component } from 'react';
import {Link} from 'react-router-dom';
import { useForm } from "react-hook-form";
import  bg from '../images/lam-home-bg.jpeg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import waves from '../images/waves.png';
import table from '../images/table.jpg';
import recruit from '../images/recruit.jpg';
import badge from '../images/badge.png';
import submit from '../images/submit.jpg';
import map from '../images/lam-map.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCaretRight, faSearch, faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Navigation from './nav';


const Complaint = () => {

    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
        alert(data)
      
        let submitFinanceSchemeRequest = await fetch('https://lamwebservices-bc4dfe5fe774.herokuapp.com/lamwebservices/v1/mailer-demon/helpdesk/complaint',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };


    return(

<>
<Navigation currentVertical="" />  

<div className="row fullWidth mOff" style={{backgroundImage:`url(${bg})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 col-md-6 standard-padding-tb marginGlass2 top-text-clearance-2 contactSideBar'> 
   
        </div>

 
        <div className="col-12 col-md-6 whiteBackground "> 
        <form className='row fullWidth formArea' onSubmit={handleSubmit(onSubmit)} >

        <h4 className="BodyFont marginGlass3"> 
           COMPLAINT/FEEDBACK
        </h4>

        <h6 className="bodyFont greyText"> 
           Have a complaint/enquiry ? Kindly fill out the form to help us serve you better. Simply provide your details below and our representative will get back to you shortly
        </h6>

        <label className="bodyFont">
          Full name
        </label>       
        <input className='form-control  form-control-2' type="text" placeholder='Fullname' {...register("fullname", {required:true})}/>

         <label className="bodyFont">
              Email
         </label>
        <input className='form-control form-control-2' type="email" placeholder='Email'{...register("email", {required:true})} />
         <div className='row pOff'>
         <div className="col-12 col-md-6 plOff ">
           <label className="bodyFont">
               Phone number
          </label>
          <input className='form-control form-control-2' type="text" placeholder='cell' {...register("mobileno", {required:true})}/>
         </div>

         <div className="col-12 col-md-6 prOff">
              <label className="bodyFont">
                   Business unit
              </label>
         <select className='form-control form-control-2' {...register("unit", {required:true})}>
            <option value="none">Select Business Unit</option>
            <option value="Investment">Investment</option>
           <option value="Client Services">Client Services </option>
           <option value="Executive Office"> Executive Office </option>
         </select>


</div>
         </div>

        <label className="bodyFont">
              Subject
         </label>
        <input className='form-control form-control-2' type="text" placeholder='subject'{...register("subject", {required:true})} />


         <label className="bodyFont">
               Complaint/Feedback
         </label>
        <input className='form-control form-control-2 commentsBoxHeight' type="textarea" placeholder='Your complaint/feedback' {...register("complaint", {required:true})}/>
 


         <div className='col-12 col-md-12'>
              <button  className="btn btn-primary leadwayOrange noDecoration headerFont fullWidth" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>Submit</button>

         </div>
        </form>
        
        </div>
      
</div>
     

</>

    )
}

export default Complaint