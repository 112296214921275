import React, { useState, useEffect, Component } from 'react';
import {useParams, Link, Outlet } from 'react-router-dom';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel'
import teamCover from '../images/resour.png';
import aboutCover from '../images/calendar.jpg';
import gStore from '../images/googleStore.png';
import aStore from '../images/appleStore.png';
import e2 from '../images/e2.jpg';
import e1 from '../images/e1.jpg';
import as from '../images/as.jpg';
import docs from '../images/docs.jpg';
import calculator from '../images/calculator.jpg';
import advise from '../images/advise.jpg';
import investment from '../images/investment.jpg';
import linkedIn from '../images/linkedin.png';
import waves from '../images/waves.png';
import ad from '../images/palms.jpg';
import editor from '../images/vusi.png';
import randomInteger from 'random-int';
import Navigation from './nav';


import{InMemoryCache,
  ApolloClient,
  gql
  } from '@apollo/client'

  const client = new ApolloClient({
    cache:  new InMemoryCache(),
    uri: "https://lam-cms.ford.com.ng/graphql",
    fetchOptions: {
      mode: 'no-cors',
    }
  });





const Insights = () => {

  const params = useParams()

  const [categoryId, setCategoryId ] = useState("")
  const [recentUpdates, setRecentUpdates] = useState([])
  const [editorUpdates, setEditorUpdate] = useState([])
  const [featuredUpdates, setFeaturedUpdates] = useState([])
  const [researchArray, setResearchArray] = useState([])
  const [commentaryArray, setCommentaryArray] = useState([])
  const [portfolioArray, setPortfolioArray] = useState([])
  const [trendsArray, setTrendsArray] = useState([])

  window.scrollTo(0, 0)
    const options =  {
        loop:true,
        autoplay:true,
        autowidth:true,
        items:5,
        arrows:true,
        margin:20,
        responsiveClass:true,
        responsive:{
            1024:{
                items:5
            },
      
            320:{
              items:1 
            }
        }
      };

    
      const settings = {
        arrows:true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
      };


     
      console.log(params.verticalId)
      useEffect( () => {

        client
        .query({
          query: gql`
         query($id:ID!){
          verticals(id:$id) {
  
            categories {
              edges {
                node {
                  categoryId
                  name
                }
              }
            }
            }
          }
     
        `,
        variables: {id:params.verticalId}
        })
      .then(async result => {
  
         let categoryid = result.data.verticals.categories.edges[0].node.categoryId
  
         setCategoryId(result.data.verticals.categories.edges[0].node.categoryId)
  
         let recentUpdatesArray = []

         let randomA = randomInteger(1, 2)
         let randomB = randomInteger(3, 4) 

      

         console.log(randomA)
         console.log(randomB)
  
       if(categoryid){
     console.log(categoryid)

     //research Data extraction
        client
        .query({
          query: gql`
          query($id: Int!) {
            allResearch(where:{categoryId:$id}) {
              edges {
                node {
                  id
                  date
                  title
                  verticalBlog {
                    description
                  }
                  contentType {
                    node {
                      description
                      name
                    }
                  }
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                
                }
              }
            }
        }
        `,
        variables: {id:categoryid}
        })
        .then(async result => {
          console.log(result.data.allResearch.edges[0].node)
           
            
         
           let researchEntityArray = []
           let researchDetailPack 
          
          for( let i=0; i <= result.data.allResearch.edges.length - 1; i++){
          
           researchDetailPack =[{date:result.data.allResearch.edges[i].node.date, Image:result.data.allResearch.edges[i].node.featuredImage.node.mediaItemUrl, title: result.data.allResearch.edges[i].node.title, postId: result.data.allResearch.edges[i].node.id, postName:result.data.allResearch.edges[i].node.__typename, description:result.data.allResearch.edges[i].node.verticalBlog.description } ]
            console.log(researchDetailPack)
       
            researchEntityArray.push(researchDetailPack)
           
            await setResearchArray(researchEntityArray)
           
              }
                if (randomA === 2 ) {
                  recentUpdatesArray.push(researchEntityArray[0])
                }
            
               
                
                console.log(researchEntityArray)
               
        })



        //marktetCommentary extraction

        client
        .query({
          query: gql`
          query($id: Int!) {
            allMarketCommentary(where:{categoryId:$id}) {
              edges {
                node {
                  id
                  date
                  title
                  verticalBlog {
                    description
                  }
                  featuredImage {
                    node {
                      mediaItemUrl
                    }
                  }
                  contentType {
                    node {
                      description
                      name
                    }
                  }
                }
              }
            }
        }
        `,
        variables: {id:categoryid}
        })
        .then(async result => {
  
           console.log(result.data.allMarketCommentary.edges)
            
           
           let commentaryEntityArray = []
           let commentaryDetailPack 
          
          for( let i=0; i <= result.data.allMarketCommentary.edges.length - 1; i++){
          
          commentaryDetailPack =[{date:result.data.allMarketCommentary.edges[i].node.date, Image:result.data.allMarketCommentary.edges[i].node.featuredImage.node.mediaItemUrl, title: result.data.allMarketCommentary.edges[i].node.title, postId: result.data.allMarketCommentary.edges[i].node.id, postName:result.data.allMarketCommentary.edges[i].node.__typename, description:result.data.allMarketCommentary.edges[i].node.verticalBlog.description} ]
        
       
            commentaryEntityArray.push(commentaryDetailPack)
           
            await setCommentaryArray(commentaryEntityArray)
           
              }
              if (randomA === 1 ) {
                recentUpdatesArray.push(commentaryEntityArray[0])
              }
                
                console.log(commentaryEntityArray)
                
        })



                //emergingTrends extraction

                client
                .query({
                  query: gql`
                  query($id: Int!) {
                    allEmergingTrends(where:{categoryId:$id}) {
                      edges {
                        node {
                          id
                          date
                          title
                          verticalBlog {
                            description
                          }
                          featuredImage {
                            node {
                              mediaItemUrl
                            }
                          }
                          contentType {
                            node {
                              description
                              name
                            }
                          }
                        }
                      }
                    }
                }
                `,
                variables: {id:categoryid}
                })
                .then(async result => {
          
                   console.log(result.data.allEmergingTrends.edges)
                   
                   let trendsEntityArray = []
                   let trendsDetailPack 
                  
                  for( let i=0; i <= result.data.allEmergingTrends.edges.length - 1; i++){
                  
                  trendsDetailPack =[{date:result.data.allEmergingTrends.edges[i].node.date, Image:result.data.allEmergingTrends.edges[i].node.featuredImage.node.mediaItemUrl, title: result.data.allEmergingTrends.edges[i].node.title, postId: result.data.allEmergingTrends.edges[i].node.id, postName:result.data.allEmergingTrends.edges[i].node.__typename, description:result.data.allEmergingTrends.edges[i].node.verticalBlog.description} ]
                
               
                    trendsEntityArray.push(trendsDetailPack)
                   
                    await setTrendsArray(trendsEntityArray)
                   
                      }
                      if (randomB === 3 ) {
                        recentUpdatesArray.push(trendsEntityArray[0])
                      }
                       
                        console.log(trendsEntityArray)
                     
                })



                //portfolio extraction

                client
                .query({
                  query: gql`
                  query($id: Int!) {
                    allPortfolio(where:{categoryId:$id}) {
                      edges {
                        node {
                          id
                          date
                          title
                          verticalBlog {
                            description
                          }
                          
                          featuredImage {
                            node {
                              mediaItemUrl
                            }
                          }
                          contentType {
                            node {
                              description
                              name
                            }
                          }
                        }
                      }
                    }
                }
                `,
                variables: {id:categoryid}
                })
                .then(async result => {
          
                   console.log(result.data.allPortfolio.edges[0].node.contentType)
                    
                   
                   let portfolioEntityArray = []
                   let portfolioDetailPack 
                  
                  for( let i=0; i <= result.data.allPortfolio.edges.length - 1; i++){
                  
                  portfolioDetailPack =[{date:result.data.allPortfolio.edges[i].node.date, Image:result.data.allPortfolio.edges[i].node.featuredImage.node.mediaItemUrl, title: result.data.allPortfolio.edges[i].node.title, postId: result.data.allPortfolio.edges[i].node.id, postName:result.data.allPortfolio.edges[i].node.__typename, description:result.data.allPortfolio.edges[i].node.verticalBlog.description } ]
                
               
                    portfolioEntityArray.push(portfolioDetailPack)
                   
                    await setPortfolioArray(portfolioEntityArray)
                   
                      }
                      if (randomB === 4 ) {
                        recentUpdatesArray.push(portfolioEntityArray[0])
                      }
                        await setRecentUpdates(recentUpdatesArray)
                        console.log(portfolioEntityArray)
                        console.log(recentUpdatesArray)
                })



              //featured extraction

                                client
                                .query({
                                  query: gql`
                                  query($id: Int!) {
                                    allFeatured(where:{categoryId:$id}) {
                                      edges {
                                        node {
                                          id
                                          date
                                          title
                                          verticalBlog {
                                            description
                                          }
                                          
                                          featuredImage {
                                            node {
                                              mediaItemUrl
                                            }
                                          }
                                          contentType {
                                            node {
                                              description
                                              name
                                            }
                                          }
                                        }
                                      }
                                    }
                                }
                                `,
                                variables: {id:categoryid}
                                })
                                .then(async result => {
                          
                                   console.log(result.data.allFeatured.edges[0].node.contentType)
                                    
                                   
                                   let featuredEntityArray = []
                                   let featuredDetailPack 
                                  
                                  for( let i=0; i <= result.data.allFeatured.edges.length - 1; i++){
                                  
                                    featuredDetailPack =[{date:result.data.allFeatured.edges[i].node.date, Image:result.data.allFeatured.edges[i].node.featuredImage.node.mediaItemUrl, title: result.data.allFeatured.edges[i].node.title, postId: result.data.allFeatured.edges[i].node.id, postName:result.data.allFeatured.edges[i].node.__typename, description:result.data.allFeatured.edges[i].node.verticalBlog.description } ]
                                
                               
                                    featuredEntityArray.push(featuredDetailPack)
                                   
                                    await setFeaturedUpdates(featuredEntityArray)
                                   
                                      }
                                      
                                             
                                    console.log(featuredEntityArray)
                                })




                                //featured extraction

                                client
                                .query({
                                  query: gql`
                                  query($id: Int!) {
                                    allEditorsChoice(where:{categoryId:$id}) {
                                      edges {
                                        node {
                                          editorpick {
                                            videoUrl
                                          }
                                        }
                                      }
                                    }
                                }
                                `,
                                variables: {id:categoryid}
                                })
                                .then(async result => {
                          
                                   console.log(result.data.allEditorsChoice.edges[0].node.editorpick.videoUrl)
                                    
                                    await setEditorUpdate(result.data.allEditorsChoice.edges[0].node.editorpick.videoUrl)
                              
                                })
        }


  
      })



       

    }, [])


   let recentUpdateDetails
  if(recentUpdates.length){

   console.log(recentUpdates)

    recentUpdateDetails = recentUpdates.map((recentUpdateDetails, i) => 
   
        <div className="row marginGlass3"> 
         
           <div className="col-12 col-md-7"> 
               <h6 className="insightDescription">{recentUpdateDetails[0].postName}</h6>
               <hr className='barStyleInsights' />
               <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${recentUpdateDetails[0].postName}/${recentUpdateDetails[0].postId}/${params.categoryId}`} >
               <h5 className="insightHeading">{recentUpdateDetails[0].title}</h5>
               </Link>
               <p className="insightDescription">{recentUpdateDetails[0].date}</p>
               <p className="insightDescription"> {recentUpdateDetails[0].description}</p>
           </div>
           <div className="col-12 col-md-5"> 
              <img src={recentUpdateDetails[0].Image} className="img-fluid" width="%" /> 
            </div>
          </div>          
       )

    }



   let featuredUpdateDetails
   if(featuredUpdates.length){
 
    console.log(featuredUpdates)
 
     featuredUpdateDetails = featuredUpdates.map((featuredUpdateDetails, i) => 
    
     
     
         <div className="row marginGlass3"> 
          
            <div className="col-12 col-md-7"> 
                <h6 className="insightDescription">{featuredUpdateDetails[0].postName}</h6>
                <hr className='barStyleInsights' />
                <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${featuredUpdateDetails[0].postName}/${featuredUpdateDetails[0].postId}/${params.categoryId}`} >
                <h5 className="insightHeading">{featuredUpdateDetails[0].title}</h5>
                </Link>
                <p className="insightDescription">{featuredUpdateDetails[0].date}</p>
                <p className="insightDescription"> {featuredUpdateDetails[0].description}</p>
            </div>
            
           </div>
 
     
           
        )
 
     }



   let researchArrayDetails
   if(researchArray.length){
 
    console.log(researchArray)
 
     researchArrayDetails = researchArray.map((researchArrayDetails, i) => 
    
      
     <div className="col-12 col-md-3"> 
     <img src={researchArrayDetails[0].Image} className="img-fluid" width="%" /> 
     <p className="insightDescription">{researchArrayDetails[0].date}</p>
     <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${researchArrayDetails[0].postName}/${researchArrayDetails[0].postId}/${params.categoryId}`} >
    <h5 className="insightHeading">{researchArrayDetails[0].title}</h5>
    </Link>
    <p className="insightDescription"> {researchArrayDetails[0].description}</p>
    </div>
 
     
           
        )
 
        
     }

     let commentaryArrayDetails

     if(commentaryArray.length){
 
      console.log(commentaryArray)
   
       commentaryArrayDetails = commentaryArray.map((commentaryArrayDetails, i) => 
      
        
       <div className="col-12 col-md-3"> 
       <img src={commentaryArrayDetails[0].Image} className="img-fluid" width="%" /> 
       <p className="insightDescription">{commentaryArrayDetails[0].date}</p>
       <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${commentaryArrayDetails[0].postName}/${commentaryArrayDetails[0].postId}/${params.categoryId}`} >
      <h5 className="insightHeading">{commentaryArrayDetails[0].title}</h5>
      </Link>
      <p className="insightDescription"> {commentaryArrayDetails[0].description}</p>
      </div>
          
       )
   
       }

    let trendsArrayDetails

       if(trendsArray.length){
   
        console.log(trendsArray)
     
         trendsArrayDetails = trendsArray.map((trendsArrayDetails, i) => 
        
          
         <div className="col-12 col-md-3"> 
         <img src={trendsArrayDetails[0].Image} className="img-fluid" width="%" /> 
         <p className="insightDescription">{trendsArrayDetails[0].date}</p>
         <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${trendsArrayDetails[0].postName}/${trendsArrayDetails[0].postId}/${params.categoryId}`} >
        <h5 className="insightHeading">{trendsArrayDetails[0].title}</h5>
        </Link>
        <p className="insightDescription"> {trendsArrayDetails[0].description}</p>
        </div>
            
         )
     
         }



    let portfolioArrayDetails

    if(portfolioArray.length){

     console.log(portfolioArray)
  
      portfolioArrayDetails = portfolioArray.map((portfolioArrayDetails, i) => 
      
      <div className="col-12 col-md-3"> 
        <img src={portfolioArrayDetails[0].Image} className="img-fluid" width="%" /> 
        <p className="insightDescription">{portfolioArrayDetails[0].date}</p>
        <Link to={`/vertical/${params.verticalName}/${params.verticalId}/insights/${portfolioArrayDetails[0].postName}/${portfolioArrayDetails[0].postId}/${params.categoryId}`} >
        <h5 className="insightHeading">{portfolioArrayDetails[0].title}</h5>
        </Link>
       
        <p className="insightDescription"> {portfolioArrayDetails[0].description}</p>
     </div>
         
      )
  
      }

    return(

<>
<Navigation currentVertical={params.verticalName} currentVerticalId={params.verticalId} />

<div className="standard-banner-padding row fullWidth mOff" style={{backgroundImage:`url(${teamCover})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <h1 className="headerFont heavyFont orangeText top-text-clearance-2"> 
     
    </h1>
</div>

<div className="row fullWidth mOff" > 
<div className="col-10 mx-auto"> 
    <div className="row standard-padding-rl-tb">
    <div className="col-12  text-center"> 
        <h3 className="orangeText headerFont"> 
         Insights
         </h3>
         <hr className='barStyleGP mx-auto' />   
           <h6 className="bodyFont"> 
        Select a category to get started
         </h6>     
     </div>

     <div className="col-12 col-md-7"> 
     <h6> Recently updated</h6>
     <hr className='barStyleInsights' />
 

       {recentUpdateDetails}
     </div>

     <div className="col-12 col-md-4 offset-md-1"> 

   



     
      {featuredUpdateDetails}



   
     
     
     </div>
    </div> 


  </div>

  <div className="col-10 mx-auto"> 
  <h6> Editors Choice </h6>
  <hr className='barStyleInsights' />
  
  </div>

  <div className="col-12 editorsChoice"> 

  <iframe width="100%" height="650" src={editorUpdates} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

  </div>


  <div className="col-12 col-md-10 mx-auto"> 
  <div className="row marginGlass3 pOff"> 
         
         <div className="col-12">
         <h6 className="insightDescription">Market Commentary</h6>
         <hr className='barStyleInsights' />
         </div>
        
          {commentaryArrayDetails}
        
     </div>



     <div className="row marginGlass3 pOff"> 
         
         <div className="col-12">
         <h6 className="insightDescription">Emerging Trends</h6>
         <hr className='barStyleInsights' />
         </div>
                {trendsArrayDetails}
   
      </div>




     <div className="row marginGlass3 pOff"> 
         
         <div className="col-12">
         <h6 className="insightDescription">Portfolio</h6>
         <hr className='barStyleInsights' />
         </div>
        
        
       
          {portfolioArrayDetails}
   
        
        
         </div>




     <div className="row marginGlass3 pOff"> 
         
         <div className="col-12">
         <h6 className="insightDescription">Research</h6>
         <hr className='barStyleInsights' />
         </div>
    
           {researchArrayDetails}
        
         </div>


     </div>

</div>








<Outlet /> 
</>

    )


}

export default Insights