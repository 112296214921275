import React, { useState, useEffect, Component } from 'react';
import {Link, Outlet} from 'react-router-dom';
import { useForm } from "react-hook-form";
import waves from '../images/waves.png';
import forms from '../images/form.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faCaretRight, faSearch, faPhone, faHouse, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import Navigation from './nav';


const ContactForms = () => {

    let { register, handleSubmit, watch, formState: { errors } } = useForm()


    let onSubmit = async function (data){
  
      
  
      
        let submitFinanceSchemeRequest = await fetch('https://charisbrain1.herokuapp.com/charis/fordwebsiteservices/mail/registration',{ 
            method: "POST",
            body:JSON.stringify(data),
            headers:{
              'Content-Type':'application/JSON',
              'Accept': 'application/JSON'
            },
            mode:'cors' 
          })
        
          .then(response => response.json())
          .then(response => { 
              alert(response)
      
          })
         
    
    
    };


    return(

<>
<Navigation currentVertical="" />  

<div className="row fullWidth mOff" style={{backgroundImage:`url(${waves})`, backgroundSize:"cover", backgroundPosition:"center"}}>
    <div className='col-12 col-md-4 mx-auto standard-padding-tb marginGlass2 top-text-clearance-2 '> 
        <h4 className="headerFont text-center"> 
          Leadway Asset Management Contact Forms
        </h4>
        <hr className='barStyleGP mx-auto' />
        </div>

        <div className="row fullWidth mOff standard-padding-bottom">

        <div className="col-12 col-md-8 mx-auto">

       
    <div className="row fullWidth mOff standard-padding-bottom">

        <div className="col-11 col-md-4 text-center"> 
        <Link to="message" className="noDecoration">
        <figure className="whiteBackground contactFormDesign" > 
        <img src={forms} width="40%"/>
            <figcaption> 
            <h5> Send a message</h5>
            </figcaption>
        </figure>
        </Link>
        </div>
        
        <div className="col-11 col-md-4 text-center"> 
        <Link to="callback" className='noDecoration'>
        <figure className="whiteBackground contactFormDesign"> 
        <img src={forms} width="40%"/>
            <figcaption> 
            <h5> Request a callback </h5>
            </figcaption>
        </figure>
        </Link>
        </div>
        

    
        <div className="col-11 col-md-4 text-center"> 
        <Link to="complaint" className="noDecoration">
        <figure className="whiteBackground contactFormDesign"> 
        <img src={forms} width="40%" />
            <figcaption> 
            <h5> Enquiry/Complaint </h5>
            </figcaption>
        </figure>
        </Link>
        </div>
     
   
    </div>
    </div>
    </div>




</div>
     
</>

    )
}

export default ContactForms